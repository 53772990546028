import {
    Autocomplete,
    Button,
    Paper,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect, useRef } from 'react';
import { generateKey } from '../../utils';
import RichTextEditor from '../../atoms/RichTextEditor';
import { makeStyles } from "tss-react/mui";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';
import trash from '../../img/icons/trash.svg';

const WeatherRow = ({ onChange, onDelete, id, item }) => {
    const [weather, setWeather] = useState(
        item
            ? item
            : {
                key: id,
                name: '',
                icon: '',
                low: 0,
                high: 0,
            }
    );
    const useStyles = makeStyles()(
        () => ({
            fieldStyle: {
                '& .MuiInputBase-root': {
                    fontSize: '0.7rem', // Smaller font size
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.7rem', // Smaller label font size
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                },
                "& fieldset": {
                    border: 'none',
                },
            },

        }))

    const { classes } = useStyles();
    useEffect(() => {
        onChange(weather);
    }, [weather]);

    return (
        <Stack spacing={1} direction="row" alignItems="center">
        <Autocomplete
            options={[
                '☁️',
                '☂️',
                '☔',
                '🌁',
                '❄️',
                '🌫️',
                '🌨️',
                '🌧️',
                '⛈️',
                '🌩️',
                '🌪️',
                '⛅',
                '☀️',
                '🥵',
                '🥶',
                '💨',
            ]}
            disableClearable
            onChange={(e, icon) => setWeather({ ...weather, icon })}
            renderInput={(params) => <TextField {...params} label="الرمز" />}
            className={classes.fieldStyle}

        />
        <TextField
            fullWidth
            //label="المدينة"
            placeholder="المدينة"
            variant="outlined"
            value={weather.name}
            onChange={({ target: { value } }) => setWeather({ ...weather, name: value })}
            className={classes.fieldStyle}
        />
        <TextField
            //label="أدنى درجة حرارة"
            placeholder="الدرجة الادنى"
            fullWidth
            variant="outlined"
            value={weather.low == 0 ? '' : weather.low}
            onChange={({ target: { value } }) => setWeather({ ...weather, low: value })}
            className={classes.fieldStyle}
        />
        <TextField
            //label="الحرارة القصوى"
            placeholder="الدرجة الاقصى"
            fullWidth
            variant="outlined"
            value={weather.high == 0 ? '' : weather.high}
            onChange={({ target: { value } }) => setWeather({ ...weather, high: value })}
            className={classes.fieldStyle}
        />            
        <IconButton color='primary' onClick={onDelete}>
            <img src={trash} alt="Trash Logo" style={{ width: '24px', height: '24px', }} />
        </IconButton>
    </Stack>
    );
};

const ConfirmWeatherCard = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmWeatherCard }) => {
    const [weather, setWeather] = useState(
        defaultValue
            ? defaultValue.items
            : [
                {
                    key: generateKey(),
                    name: '',
                    icon: '',
                    low: 0,
                    high: 0,
                },
            ]
    );
    const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([{
        items: weather,
        body: body,
        category: 'توقعات الطقس',
        title: 'الطقس',
        date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`
    }]);
    const { data } = props;

    const previewCompRef = useRef();
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '25px',
                padding: '25px 15px',
                //height: '100%',
            }
        })
    );
    const PreviewFn = () => {
        setItems([{
            items: weather,
            body: body,
            category: 'توقعات الطقس',
            title: 'الطقس',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    const { classes } = useStyles();

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);
    return (
        <>
            <Grid container spacing={3} mt={2} mb={4} sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }} columns={{ lg: 12 }}>
                <Grid item xs={6} >
                    <Stack spacing={3} className={classes.content} direction="column">
                        <Stack direction="column" divider={<Divider />} spacing={2}>
                            {weather.map((city, index) => (
                                <WeatherRow
                                    key={city.key}
                                    id={city.key}
                                    onChange={(newCity) => {
                                        let temp = [...weather];
                                        temp.splice(index, 1, newCity);
                                        setWeather(temp);
                                    }}
                                    item={city}
                                    onDelete={() => {
                                        let temp = [...weather];
                                        temp.splice(index, 1);
                                        setWeather(temp);
                                    }}
                                />
                            ))}
                        </Stack>
                        <Button
                            fullWidth
                            onClick={() =>
                                setWeather([
                                    ...weather,
                                    {
                                        key: generateKey(),
                                        name: '',
                                        icon: '',
                                        low: 0,
                                        high: 0,
                                    },
                                ])
                            }
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    color: '#000000', // Set the icon color to black
                                },
                            }}
                        >
                            <AddCircleOutlineIcon />
                        </Button>
                        <RichTextEditor defaultValue={body} onChange={setBody} minHeightRichText={400} />

                        {/* <Grid container mt={4} columns={{ sm: 1, md: 12 }} justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} md={4} >
                                <Button size='small' variant="outlined" style={{ color: '#FF6150', borderRadius: '15px', padding: '10px' }} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                            </Grid>
                            <Grid item xs={6} md={6} sx={{display:'grid'}}>
                                <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '15px', padding: '10px', }} endIcon={<SaveIcon />} onClick={saveWeatherNews}>حفظ في مكتبة الفئات</Button>
                            </Grid>
                        </Grid> */}
                    </Stack>
                
                </Grid>
                <Grid item xs={6}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmWeatherCard(weather, body, defaultValue.date)}
                        >
                            تأكيد
                        </Button>
                        <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '23%' }} onClick={PreviewFn}>معاينة</Button>
                    </Grid>
                </Grid>

            </Grid >
        </>


    );
};

export default ConfirmWeatherCard;
