import {
    Autocomplete,
    Button,
    Card,
    Snackbar,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect, useRef } from 'react';
import { generateKey } from '../../utils';
import RichTextEditor from '../../atoms/RichTextEditor';
import { makeStyles } from "tss-react/mui";
import HTMLViewer from '../../components/HTMLViewer';
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';
import trash from '../../img/icons/trash.svg';

const WeatherRow = ({ onChange, onDelete, id, item }) => {
    const [weather, setWeather] = useState(
        item
            ? item
            : {
                key: id,
                name: '',
                icon: '',
                low: 0,
                high: 0,
            }
    );

    const useStyles = makeStyles()(
        () => ({
            fieldStyle: {
                '& .MuiInputBase-root': {
                    fontSize: '0.7rem', // Smaller font size
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.7rem', // Smaller label font size
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                },
                "& fieldset": {
                    border: 'none',
                },
            },

        }))

    const { classes } = useStyles();

    useEffect(() => {
        onChange(weather);
    }, [weather]);

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <Autocomplete
                options={[
                    '☁️',
                    '☂️',
                    '☔',
                    '🌁',
                    '❄️',
                    '🌫️',
                    '🌨️',
                    '🌧️',
                    '⛈️',
                    '🌩️',
                    '🌪️',
                    '⛅',
                    '☀️',
                    '🥵',
                    '🥶',
                    '💨',
                ]}
                disableClearable
                onChange={(e, icon) => setWeather({ ...weather, icon })}
                renderInput={(params) => <TextField {...params} label="الرمز" />}
                className={classes.fieldStyle}

            />
            <TextField
                fullWidth
                //label="المدينة"
                placeholder="المدينة"
                variant="outlined"
                value={weather.name}
                onChange={({ target: { value } }) => setWeather({ ...weather, name: value })}
                className={classes.fieldStyle}
            />
            <TextField
                //label="أدنى درجة حرارة"
                placeholder="الدرجة الادنى"
                fullWidth
                variant="outlined"
                value={weather.low == 0 ? '' : weather.low}
                onChange={({ target: { value } }) => setWeather({ ...weather, low: value })}
                className={classes.fieldStyle}
            />
            <TextField
                //label="الحرارة القصوى"
                placeholder="الدرجة الاقصى"
                fullWidth
                variant="outlined"
                value={weather.high == 0 ? '' : weather.high}
                onChange={({ target: { value } }) => setWeather({ ...weather, high: value })}
                className={classes.fieldStyle}
            />            
            <IconButton color='primary' onClick={onDelete}>
                <img src={trash} alt="Trash Logo" style={{ width: '24px', height: '24px', }} />
            </IconButton>
        </Stack>
    );
};



const NewsWeather = (props) => {
    const [weather, setWeather] = useState(
        [
            {
                key: generateKey(),
                name: '',
                icon: '',
                low: 0,
                high: 0,
            },
        ]
    );
    const previewCompRef = useRef();
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [body, setBody] = useState('');
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
        background: '#FFC300'
    });
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            weatherContainer: {
                background: '#fbfbfb',
                padding: '20px',
                borderRadius: '25px',
                boxShadow: '0px 0px 5px #eee',
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '25px',
                padding: '25px 15px',
               // height: '100%',
            }

        })
    );
    const db = getFirestore();
    const { company, data } = props;
    const { classes } = useStyles();

    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };

    const saveWeatherNews = async () => {
        const docTitle = getDateId(company);
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: {
                    id: generateKey(),
                    items: weather,
                    body: body,
                    category: 'توقعات الطقس',
                    title: 'الطقس',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                Image: data.data().Image,
                Currency: data.data().Currency,
                data: data.data().data
            }).then((data) => {
                console.log('done update');
            });
        } else {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: [],
                Stocks: '',
                Weather: {
                    id: generateKey(),
                    items: weather,
                    body: body,
                    category: 'توقعات الطقس',
                    title: 'الطقس',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                Image: [],
                Currency: '',
                data: {
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`,
                    company: company
                }
            }).then((data) => {
                console.log('done');
            });
        }
        setSnackBar({
            isOpen: true,
			background: '#51F9C3',
            message: 'تم الحفظ في مكتبة الفئات',
        });
    }
    const previewWeatherNews = () => {
        // console.log(weather);
        setIntro(body);
        setItems(prev => [prev, items]);
        console.log(items, intro, weather);
    }
    const PreviewFn = () => {
        setItems([{
            items: weather,
            body: body,
            category: 'توقعات الطقس',
            title: 'الطقس',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);
    return (
        <>
        			<Grid container sx={{ justifyContent: 'center', }} lg={12}>

            <Grid container mt={1} sx={{ justifyContent: 'space-between', }} lg={10}>
                <Grid item xs={6} lg={5.8} >
                    <Stack spacing={3} className={classes.content} direction="column">
                        <Stack direction="column" divider={<Divider />} spacing={2}>
                            {weather.map((city, index) => (
                                <WeatherRow
                                    key={city.key}
                                    id={city.key}
                                    onChange={(newCity) => {
                                        let temp = [...weather];
                                        temp.splice(index, 1, newCity);
                                        setWeather(temp);
                                    }}
                                    item={city}
                                    onDelete={() => {
                                        let temp = [...weather];
                                        temp.splice(index, 1);
                                        setWeather(temp);
                                    }}
                                />
                            ))}
                        </Stack>
                        <Button
                            fullWidth
                            onClick={() =>
                                setWeather([
                                    ...weather,
                                    {
                                        key: generateKey(),
                                        name: '',
                                        icon: '',
                                        low: 0,
                                        high: 0,
                                    },
                                ])
                            }
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    color: '#000000', // Set the icon color to black
                                },
                            }}
                        >
                            <AddCircleOutlineIcon />
                        </Button>
                        <RichTextEditor defaultValue={body} onChange={setBody} minHeightRichText={400}/>

                        {/* <Grid container mt={4} columns={{ sm: 1, md: 12 }} justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} md={4} >
                                <Button size='small' variant="outlined" style={{ color: '#FF6150', borderRadius: '15px', padding: '10px' }} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                            </Grid>
                            <Grid item xs={6} md={6} sx={{display:'grid'}}>
                                <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '15px', padding: '10px', }} endIcon={<SaveIcon />} onClick={saveWeatherNews}>حفظ في مكتبة الفئات</Button>
                            </Grid>
                        </Grid> */}
                    </Stack>
                    <Grid container mt={4} columns={{ sm: 12, md: 12, lg: 12 }} sx={{justifyContent:"space-between", alignItems:"center"}}>					
							<Grid item xs={6} md={6} lg={6} >
								<Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '5px', padding: '10px', }} onClick={saveWeatherNews}>حفظ في مكتبة المحتوى</Button>
							</Grid>
							<Grid item xs={6} md={3} lg={3} sx={{ textAlign: "right" }}>
								<Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px',width: '60%' }} onClick={PreviewFn}>معاينة</Button>
							</Grid>
						</Grid>
                </Grid>
                <Grid item xs={6} lg={5.8} style={{ paddingTop: 0 }}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
            </Grid>
            </Grid>
            <Snackbar
                ContentProps={{
                    sx: {
                        background: snackBar.background,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }
                }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    )


}
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(NewsWeather);