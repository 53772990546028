import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    IconButton,
    TextField,
    OutlinedInput,
    Typography,
    Select,
    Checkbox,
    MenuItem,
    ListItemText,
    Snackbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Delete, Edit } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, addDoc, getDoc, getFirestore, getDocs, setDoc, collection, query, where } from 'firebase/firestore';

import { makeStyles } from "tss-react/mui";
import { isEmpty } from 'lodash';



const AddEditSubscriber = ({ buttonName, name, email, list, id, index, handleAddSubscriber, onCloseIt }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [subName, setSubName] = useState(name);
    const [subEmail, setSubEmail] = useState(email);
    const [subList, setSubList] = useState(list);
    const [selectedList, setSelectedList] = useState([]);
    // const [visible, setVisible] = useState(false);
    const [open, setOpen] = useState(true)

    const db = getFirestore();

    const useStyles = makeStyles()(
        () => ({
            'muirtl-ebyfgi-MuiButtonBase-root-MuiCheckbox-root':{
                padding: '0px !important',
            },
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            content: {
                paddingRight: '20px',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            paperList: {
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0 30px',
                minHeight: 60,
                marginBottom: 15
            },
            editDeleteBtns: {
                display: 'flex',
            },
            btns: {
                color: '#ff6633'
            },
            textContainer: {
                width: '100%',
                margin: '20px 0',
            },
            textPaper: {
                width: '100%',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px'
            },
            submitBtnGrid: {
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '20px 0',
            },
            submitBtn: {
                background: '#f63',
                color: '#FFF',
            },
            addCatForm: {
                display: 'flex',
                flexDirection: 'column',
            },
            item: {
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
            },

        })
    );



    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const { classes } = useStyles();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        onCloseIt();
    };

    return (
        <>
            <Drawer open={open} anchor={"right"} onClose={toggleDrawer(false)}  >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '400px',
                    }}
                >
                    <Grid container sx={{ width: '100%', ml: 4, mr: 4, }} direction='column' justifyContent="center" alignItems="stretch">
                        <Grid item >
                            <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                اسم المشترك
                            </Typography>
                        </Grid>

                        <Grid item md={8} >
                            <TextField
                                size='small'
                                type="text"
                                variant='outlined'
                                onChange={(e) => setSubName(e.target.value)}
                                value={subName}
                                fullWidth
                                placeholder="اسم المشترك"
                                sx={{
                                    mb: 4,
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                البريد الإلكتروني
                            </Typography>
                        </Grid>
                        <Grid item md={8} >
                            <TextField
                                size='small'
                                type="email"
                                variant='outlined'
                                onChange={(e) => setSubEmail(e.target.value)}
                                value={subEmail}
                                fullWidth
                                placeholder="البريد الإلكتروني"
                                sx={{
                                    mb: 4,
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                قوائم المشتركين
                            </Typography>
                        </Grid>
                        <Grid item md={8} className={classes.item}>
                            <Select
                                style={{ width: '100%', fontSize: '0.7rem', borderRadius: '7px' }}
                                size='small'
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedList}
                                onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) =>
                                    selected
                                        .map((id) => subList.find((item) => item.id === id)?.name)
                                        .join(', ')
                                }
                                sx={{
                                    mb: 4,
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '7px', // Adjust border radius
                                        },
                                    },
                                }}
                            >
                                {subList.map((item) => (
                                    <MenuItem key={item.id} value={item.id} style={{padding:'0px !important', fontSize: '10px'}}>
                                        <Checkbox size='small' checked={selectedList.includes(item.id)}/>
                                        <ListItemText size="small" primary={item.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                style={{ backgroundColor: '#FF6150', borderRadius: '7px' }}
                                size='small'
                                variant="contained"
                                onClick={e => handleAddSubscriber(subName, subEmail, selectedList, id, index)} >
                                {buttonName}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {/* <Grid container spacing={2} mb={2} columns={{ xs: 1, sm: 1, md: 12 }} style={{ marginRight: 0, width: '100%' }}>
                    <Paper elevation={0} className={classes.textPaper}>
                        <Grid item className={classes.addCatForm}>
                            <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={2}>
                                    <Typography variant="body1">
                                        الإسم
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={10}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-name"
                                        index={index}
                                        value={subName}
                                        onChange={(e) => setSubName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.addCatForm} mt={2}>
                            <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={2}>
                                    <Typography variant="body1">
                                        البريد اللإلكتروني
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={10}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-name"
                                        index={index}
                                        value={subEmail}
                                        onChange={(e) => setSubEmail(e.target.value)}
                                    />
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xs={1} sm={1} md={12} mt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                size='small'
                                variant="contained"
                                onClick={e => handleAddSubscriber(subName, subEmail, id, index)} >
                                {buttonName}
                            </Button>
                        </Grid>
                    </Paper>
                </Grid> */}
            </Drawer>
        </>

    );
};

export default AddEditSubscriber;
