import { Divider, Grid, Stack, TextField, Paper, Button, Typography, Autocomplete } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import RichTextEditor from '../../atoms/RichTextEditor';
import ImageUploader from '../ImageUploader';
import { makeStyles } from "tss-react/mui";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc, query, where } from 'firebase/firestore';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';
import { generateKey } from '../../utils';

const ConfirmNewsWithImage = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmNewsWithImageCard }) => {
    const [section, setSection] = useState(
        defaultValue && defaultValue.section ? defaultValue.section : ''
    );
    console.log(defaultValue);
    const [title, setTitle] = useState(
        defaultValue && defaultValue.title ? defaultValue.title : ''
    );
    const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');
    const [image, setImage] = useState(
        defaultValue && defaultValue.image ? defaultValue.image : ''
    );
    const [credits, setCredits] = useState(
        defaultValue && defaultValue.credits ? defaultValue.credits : ''
    );
    const [alt, setAlt] = useState(defaultValue && defaultValue.alt ? defaultValue.alt : '');
    const [catOptions, setCatOptions] = useState([]);
    const [category, setCategory] = useState(defaultValue.category);
    const [valueDate, setValueDate] = useState(_formatDate(defaultValue.date, '/'));
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const db = getFirestore();
    const previewCompRef = useRef();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            marginNormal: {
                marginTop: '25px',
                marginBottom: '25px'
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '25px',
                padding: '25px',
            }
        })
    );

    const { classes } = useStyles();

    const dateFormat = (inputDate, format) => {
        //parse the input date
        const date = new Date(inputDate);
        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        //replace the month
        format = format.replace("MM", month.toString().padStart(2, "0"));

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2, 2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2, "0"));

        return format;
    }

    const getCategories = async () => {
        const categoriesRef = collection(db, "Categories");
        const q = query(categoriesRef, where("company", "==", "mujaz"));
        let data = await getDocs(q);
        data.forEach((doc) => {
            setCatOptions(catOptions => [...catOptions, doc.data().name]);
        });

    }
    function _formatDate(dateIn, symbol) {
        let date = dateIn.split('-')[1] + '-' + dateIn.split('-')[0] + '-' + dateIn.split('-')[2];
        date = new Date(date);
        let yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        if (symbol != '-') {
            mm = mm.toString().padStart(2, '0');
        }
        let dd = date.getDate();
        return String(mm + symbol + dd + symbol + yyyy);
    }
    const handleDateChange = (newValue) => {
        setValueDate(newValue);
    }
    const PreviewFn = () => {
        setItems([{
            id: generateKey(),
            category: category,
            section: section,
            title: title,
            image: image,
            alt: alt,
            credits: credits,
            body: body,
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    useEffect(() => {
        getCategories();
    }, []);
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);
    return (
        <>
            <Grid container spacing={3} mt={1} mb={4} sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }} columns={{ lg: 12 }}>
                <Grid item lg={6}>
                    <Stack spacing={3} className={classes.content} direction="column">
                        <Stack direction="column" spacing={1}>
                            <Grid container spacing={0} columns={{ lg: 12 }} sx={{ justifyContent: 'space-between', }}>
                                <Grid item lg={5.6}>
                                    <Autocomplete
                                        options={catOptions}
                                        disableClearable
                                        onChange={(e, cat) => setCategory(cat)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="الفئة"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // endAdornment: (
                                                    // 	<img src={sectionsIcon} alt="Custom Icon" style={{ width: '40px', height: '40px', color: '#FF6150' }} />
                                                    // ),
                                                }}
                                            />}
                                        sx={{
                                            mb: 3,
                                            '& .MuiInputBase-root': {
                                                fontSize: '0.7rem', // Smaller font size
                                            },
                                            '& .MuiInputLabel-root': {
                                                fontSize: '0.7rem', // Smaller label font size
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '15px'
                                            },
                                            "& fieldset": {
                                                border: 'none',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={5.6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            placeholder="التاريخ"
                                            inputFormat="MM/dd/yyyy"
                                            value={valueDate}
                                            onChange={handleDateChange}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            color: '#FF6150', // Change the color of the icon
                                                        },
                                                        '& .MuiInputBase-root': { // Replace with your actual class name
                                                            height: '53px',
                                                            borderRadius: '15px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'center',
                                                            alignSelf: 'baseline',
                                                        },
                                                        "& fieldset": {
                                                            border: 'none',
                                                        },
                                                        width: '100%'
                                                    }} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <TextField
                                fullWidth
                                value={title}
                                onChange={({ target: { value } }) => setTitle(value)}
                                variant="outlined"
                                label="العنوان الرئيسي"
                                placeholder="العنوان الرئيسي"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '15px'
                                    },
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                }}
                            />
                        </Stack>

                        <Grid container sx={{ justifyContent: 'space-between' }} columns={{ sm: 1, md: 12, lg: 12 }}>
                            <Grid item sm={12} md={12} lg={12} >
                                <ImageUploader textImage={'إضافة صورة'} image={image} onChange={setImage} />
                            </Grid>
                            {/* <Grid item sm={8} md={10} lg={8.5}>
									<TextField
										fullWidth
										value={imageURL}
										onChange={({ target: { value } }) => setImageURL(value)}
										variant="outlined"
										label="رابط الصورة"
										placeholder="رابط الصورة"
										inputProps={
											{ readOnly: true, }
										}
										sx={{
											'& .MuiInputBase-root': {
												fontSize: '0.7rem', // Smaller font size
											},
											'& .MuiInputLabel-root': {
												fontSize: '0.7rem', // Smaller label font size
											},
											'& .MuiOutlinedInput-root': {
												borderRadius: '15px'
											},
											"& fieldset": {
												border: 'none',
											},
										}}
									/>
								</Grid> */}

                        </Grid>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                fullWidth
                                value={credits}
                                onChange={({ target: { value } }) => setCredits(value)}
                                variant="outlined"
                                label="المصدر"
                                placeholder="المصدر"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '15px'
                                    },
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                value={alt}
                                onChange={({ target: { value } }) => setAlt(value)}
                                variant="outlined"
                                label="الوصف"
                                placeholder="الوصف"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.7rem', // Smaller font size
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem', // Smaller label font size
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '15px',
                                    },
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                }}
                            />

                        </Stack>
                        <RichTextEditor defaultValue={body} onChange={setBody} minHeightRichText={400} />

                    </Stack>
                    {/* <Grid container mt={4} columns={{ sm: 12, md: 12, lg: 12 }} sx={{justifyContent:"space-between", alignItems:"center"}}>					
							<Grid item xs={6} md={6} lg={6} >
								<Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '5px', padding: '10px', }} onClick={saveNewsWithImage}>حفظ في مكتبة المحتوى</Button>
							</Grid>
							<Grid item xs={6} md={3} lg={3} sx={{ textAlign: "right" }}>
								<Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px',width: '60%' }} onClick={PreviewFn}>معاينة</Button>
							</Grid>
						</Grid> */}
                </Grid>
                <Grid item xs={6}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>

            </Grid>
            <Grid container mt={4}>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        className={classes.cancelBtn}
                        variant="contained"
                        onClick={handleCancelConfirmCard}
                    >
                        رجوع
                    </Button>
                    <Button
                        className={classes.confirmBtn}
                        variant="contained"
                        onClick={e => handleEditConfirmNewsWithImageCard(alt, credits, image, section, title, body, category, valueDate, defaultValue)}
                    >
                        تأكيد
                    </Button>
                    <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '23%' }} onClick={PreviewFn}>معاينة</Button>					</Grid>
            </Grid>
            {/* </Grid > */}

        </>
    );
};

export default ConfirmNewsWithImage;
