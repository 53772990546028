import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Paper,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect, useRef } from 'react';
import { generateKey } from '../../utils';
import { makeStyles } from "tss-react/mui";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';
import trash from '../../img/icons/trash.svg';
import RichTextEditor from '../../atoms/RichTextEditor';

const CurrencyRow = ({ onChange, onDelete, id, item }) => {
    const [currency, setCurrency] = useState(
        item
            ? item
            : {
                key: id,
                name: '',
                icon: '',
                value: 0,
            }
    );

    useEffect(() => {
        onChange(currency);
    }, [currency]);

    const useStyles = makeStyles()(
        () => ({
            fieldStyle: {
                '& .MuiInputBase-root': {
                    fontSize: '0.7rem', // Smaller font size
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.7rem', // Smaller label font size
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                },
                "& fieldset": {
                    border: 'none',
                },
            },

        }))

    const { classes } = useStyles();

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <Autocomplete
                options={[
                    '🇪🇺',
                    '🇬🇧',
                    '🇺🇸',
                    '🇹🇷',
                    '🇱🇧',
                    '🇸🇾',
                    '🇮🇶',
                    '🇵🇸',
                    '🇪🇬',
                    '🇸🇩',
                    '🇩🇿',
                    '🇹🇳',
                    '🇲🇦',
                    '🇱🇾',
                    '🇯🇴',
                    '🇮🇷',
                    '🇷🇺',
                    '🇸🇦',
                    '🇰🇼',
                    '🇾🇪',
                    '🇴🇲',
                    '🇧🇭',
                    '🇦🇪',
                    '🇶🇦',
                ]}
                disableClearable
                onChange={(e, icon) => setCurrency({ ...currency, icon })}
                renderInput={(params) => <TextField {...params} label="العلم" />}
                className={classes.fieldStyle}
            />
            <TextField
                fullWidth
                //label="البلد"
                placeholder="البلد"
                variant="outlined"
                value={currency.name}
                onChange={({ target: { value } }) => setCurrency({ ...currency, name: value })}
                className={classes.fieldStyle}
            />
            <TextField
                //label="القيمة"
                placeholder="قيمة الشراء"
                fullWidth
                variant="outlined"
                value={currency.purshaseValue == 0 ? '' : currency.purshaseValue}
                onChange={({ target: { value } }) => setCurrency({ ...currency, purshaseValue: value })}
                className={classes.fieldStyle}
            />
            <TextField
                //label="القيمة"
                placeholder="قيمة المبيع"
                fullWidth
                variant="outlined"
                value={currency.salesValue == 0 ? '' : currency.salesValue}
                onChange={({ target: { value } }) => setCurrency({ ...currency, salesValue: value })}
                className={classes.fieldStyle}
            />
            <IconButton color='primary' onClick={onDelete}>
                <img src={trash} alt="Trash Logo" style={{ width: '24px', height: '24px', }} />
            </IconButton>
        </Stack>
    );
};

const ConfirmCurrenciesCard = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmCurrenciesCard }) => {
    console.log(defaultValue);
    const [currencies, setCurrencies] = useState(
        defaultValue
            ? defaultValue.items
            : [
                {
                    key: generateKey(),
                    name: '',
                    icon: '',
                    value: '',
                },
            ]
    );
    const previewCompRef = useRef();
    const [body, setBody] = useState(defaultValue && defaultValue.body ? defaultValue.body : '');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '25px',
                padding: '25px 15px',
                //height: '100%',
            }
        })
    );
    const PreviewFn = () => {
        setItems([{
            items: currencies,
            category: 'أسعار المال',
            title: 'المال',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    const { classes } = useStyles();

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);


    return (
        <>
            <Grid container spacing={3} mt={2} mb={4} sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }} columns={{ lg: 12 }}>
            <Grid item xs={6} lg={5.8} >
                        <Stack spacing={3} className={classes.content} direction="column">
                            <Stack direction="column" divider={<Divider />} spacing={2}>
                                {currencies.map((city, index) => (
                                    <CurrencyRow
                                        key={city.key}
                                        id={city.key}
                                        onChange={(newCity) => {
                                            let temp = [...currencies];
                                            temp.splice(index, 1, newCity);
                                            setCurrencies(temp);
                                        }}
                                        item={city}
                                        onDelete={() => {
                                            let temp = [...currencies];
                                            temp.splice(index, 1);
                                            setCurrencies(temp);
                                        }}
                                    />
                                ))}
                            </Stack>
                            <Button
                                fullWidth
                                onClick={() =>
                                    setCurrencies([
                                        ...currencies,
                                        {
                                            key: generateKey(),
                                            name: '',
                                            icon: '',
                                            value: '',
                                        },
                                    ])
                                }
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: '#000000', // Set the icon color to black
                                    },
                                }}
                            >
                                <AddCircleOutlineIcon />
                            </Button>
                            <RichTextEditor defaultValue={body} onChange={setBody} minHeightRichText={400}/>
                            {/* <Grid container mt={4} columns={{ sm: 1, md: 12 }} justifyContent="space-between" alignItems="center">
                                <Grid item xs={6} md={4} >
                                    <Button size='small' variant="outlined" style={{ color: '#FF6150', borderRadius: '15px', padding: '10px' }} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                                </Grid>
                                <Grid item xs={6} md={6} sx={{ display: 'grid' }}>
                                    <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '15px', padding: '10px', }} endIcon={<SaveIcon />} onClick={saveNewsCurrency}>حفظ في مكتبة الفئات</Button>
                                </Grid>
                            </Grid> */}
                        </Stack>

                        {/* <Grid container mt={4} columns={{ sm: 12, md: 12, lg: 12 }} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                            <Grid item xs={6} md={6} lg={6} >
                                <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '5px', padding: '10px', }} onClick={saveNewsCurrency}>حفظ في مكتبة المحتوى</Button>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} sx={{ textAlign: "right" }}>
                                <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '60%' }} onClick={PreviewFn}>معاينة</Button>
                            </Grid>
                        </Grid> */}
                    </Grid>
                <Grid item xs={6}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmCurrenciesCard(currencies, defaultValue.date)}
                        >
                            تأكيد
                        </Button>
                        <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '23%' }} onClick={PreviewFn}>معاينة</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>

    );
};

export default ConfirmCurrenciesCard;
