import { Button, Divider, Stack, Typography, Grid, Snackbar } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import RichTextEditor from '../../atoms/RichTextEditor';
import { generateKey } from '../../utils';
import StockRow from '../../components/StockRow';
import ImageUploader from '../../components/ImageUploader';
import { makeStyles } from "tss-react/mui";
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import HTMLViewer from '../../components/HTMLViewer';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';

const NewsStocks = (props) => {
    const [stocks, setStocks] = useState(
        [
            {
                key: generateKey(),
                name: '',
                value: 0,
                change: '0',
            },
        ]
    );
    const previewCompRef = useRef();
    const [body, setBody] = useState('');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
        background: '#FFC300'
    });
    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '25px',
                padding: '25px 15px',
                //height: '100%',
            }

        })
    );
    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };
    const saveNewsStocks = async () => {
        const docTitle = getDateId(company);
        console.log(docTitle);
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: data.data().News,
                Stocks: {
                    id: generateKey(),
                    items: stocks,
                    body: body,
                    category: 'البورصة',
                    title: 'توقعات البورصة',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                Weather: data.data().Weather,
                Image: data.data().Image,
                Currency: data.data().Currency,
                data: data.data().data
            }).then((data) => {
                console.log('done update');
            });
        } else {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: [],
                Stocks: {
                    id: generateKey(),
                    items: stocks,
                    body: body,
                    category: 'البورصة',
                    title: 'توقعات البورصة',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                Weather: '',
                Image: [],
                Currency: '',
                data: {
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`,
                    company: company
                }
            }).then((data) => {
                console.log('done');
            });
        }
        setSnackBar({
            isOpen: true,
			background: '#51F9C3',
            message: 'تم الحفظ في مكتبة الفئات',
        });
    }
    const PreviewFn = () => {
        setItems([{
            items: stocks,
            body: body,
            category: 'البورصة',
            title: 'توقعات البورصة',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    const { classes } = useStyles();
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    return (
        <>
            <Grid container sx={{ justifyContent: 'center', }} lg={12}>

                <Grid container sx={{ justifyContent: 'space-between', }} lg={10}>
                    <Grid item lg={5.8} >
                        <Stack spacing={3} className={classes.content} direction="column">
                            {/* <Typography variant="h5">البورصة</Typography> */}
                            <Stack direction="column" divider={<Divider />} spacing={2}>
                                {stocks.map((stock, index) => (
                                    <StockRow
                                        key={stock.key}
                                        id={stock.key}
                                        onChange={(newStock) => {
                                            let temp = [...stocks];
                                            temp.splice(index, 1, newStock);
                                            setStocks(temp);
                                        }}
                                        item={stock}
                                        onDelete={() => {
                                            let temp = [...stocks];
                                            temp.splice(index, 1);
                                            setStocks(temp);
                                        }}
                                    />
                                ))}
                            </Stack>
                            <Button
                                fullWidth
                                onClick={() =>
                                    setStocks([
                                        ...stocks,
                                        {
                                            key: generateKey(),
                                            name: '',
                                            value: 0,
                                            change: '+0',
                                        },
                                    ])
                                }
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: '#000000', // Set the icon color to black
                                    },
                                }}
                            >
                                <AddCircleOutlineIcon />
                            </Button>
                            <RichTextEditor defaultValue={body} onChange={setBody} minHeightRichText={400} />


                        </Stack>
                        <Grid container mt={4} columns={{ sm: 12, md: 12, lg: 12 }} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                            <Grid item xs={6} md={6} lg={6} >
                                <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '5px', padding: '10px', }} onClick={saveNewsStocks}>حفظ في مكتبة المحتوى</Button>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} sx={{ textAlign: "right" }}>
                                <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '60%' }} onClick={PreviewFn}>معاينة</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={5.8} style={{ paddingTop: 0 }}>
                        <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                    </Grid>
                </Grid>

            </Grid>
            <Snackbar
                ContentProps={{
                    sx: {
                        background: snackBar.background,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }
                }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(NewsStocks);
