import { Stack, TextField, Grid, Paper, Button, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import ImageUploader from '../ImageUploader';
import { makeStyles } from "tss-react/mui";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import Preview from '../../components/Preview';


const ConfirmImageCard = ({ props, defaultValue, handleCancelConfirmCard, handleEditConfirmImageCard }) => {
    const [section, setSection] = useState(
        defaultValue && defaultValue.section ? defaultValue.section : ''
    );
    const [title, setTitle] = useState(
        defaultValue && defaultValue.title ? defaultValue.title : ''
    );
    const [image, setImage] = useState(
        defaultValue && defaultValue.image ? defaultValue.image : ''
    );
    const [credits, setCredits] = useState(
        defaultValue && defaultValue.credits ? defaultValue.credits : ''
    );
    const [imageURL, setImageURL] = useState(defaultValue && defaultValue.imageURL ? defaultValue.imageURL : '');
    const [alt, setAlt] = useState(defaultValue && defaultValue.alt ? defaultValue.alt : '');
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const previewCompRef = useRef();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                padding: '20px'

            },
            paper: {
                padding: '20px',
            },
            cancelBtn: {
                boxShadow: '0px 3px 6px #00000026',
            },
            confirmBtn: {
                marginRight: '15px',
                boxShadow: '0px 3px 6px #00000026',
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '25px',
                padding: '25px 15px',
                //height: '100%',
            }
        })
    );

    const { classes } = useStyles();
    const PreviewFn = () => {
        setItems([{
            section: section,
            title: title,
            image: image,
            imageURL: imageURL,
            credits: credits,
            alt: alt,
            category: 'صورة',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }

    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    return (
        <>
            <Grid container spacing={3} mt={2} mb={4} sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }} columns={{ lg: 12 }}>
                <Grid item xs={6} >
                    <Stack spacing={3} className={classes.content} direction="column">
                        {/* <Typography variant="h5">صورة</Typography> */}
                        <Stack direction="column" spacing={3}>
                            <TextField
                                fullWidth
                                value={section}
                                onChange={({ target: { value } }) => setSection(value)}
                                variant="outlined"
                                label="الفقرة"
                                placeholder="الفقرة"
                            />
                            <TextField
                                fullWidth
                                value={title}
                                onChange={({ target: { value } }) => setTitle(value)}
                                variant="outlined"
                                label="عنوان الصورة"
                                placeholder="عنوان الصورة"
                            />
                        </Stack>
                        <ImageUploader textImage={'إضافة صورة'} image={image} onChange={setImage} />
                        <Stack direction="row" spacing={1}>
                            <TextField
                                fullWidth
                                value={credits}
                                onChange={({ target: { value } }) => setCredits(value)}
                                variant="outlined"
                                label="المصدر"
                                placeholder="المصدر"
                            />
                            <TextField
                                fullWidth
                                value={alt}
                                onChange={({ target: { value } }) => setAlt(value)}
                                variant="outlined"
                                label="وصف الصورة"
                                placeholder="وصف الصورة"
                            />
                            <TextField
                                fullWidth
                                value={imageURL}
                                onChange={({ target: { value } }) => setImageURL(value)}
                                variant="outlined"
                                label="رابط الصورة"
                                placeholder="رابط الصورة"
                            />
                        </Stack>
                    </Stack>
                    {/* <Grid container mt={4} columns={{ sm: 12, md: 12, lg: 12 }} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Grid item xs={6} md={6} lg={6} >
                            <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '5px', padding: '10px', }} onClick={saveNewsImage}>حفظ في مكتبة المحتوى</Button>
                        </Grid>
                        <Grid item xs={6} md={3} lg={3} sx={{ textAlign: "right" }}>
                            <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '60%' }} onClick={PreviewFn}>معاينة</Button>
                        </Grid>
                    </Grid> */}

                </Grid>
                <Grid item xs={6}>
                    <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                </Grid>
                <Grid container mt={4}>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={handleCancelConfirmCard}
                        >
                            رجوع
                        </Button>
                        <Button
                            className={classes.confirmBtn}
                            variant="contained"
                            onClick={e => handleEditConfirmImageCard(alt, credits, image, section, title, defaultValue)}
                        >
                            تأكيد
                        </Button>
                        <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '23%' }} onClick={PreviewFn}>معاينة</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ConfirmImageCard;
