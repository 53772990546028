import React, { useEffect, useState } from 'react';
import {
    Grid,
    Paper,
    Button,
    Typography,
    Autocomplete,
    TextField,
    Box,
    Link,
    CircularProgress,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { makeStyles } from "tss-react/mui";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getFirestore, getDocs, collection, query, where, Timestamp } from 'firebase/firestore';
import { startOfDay, endOfDay, addDays, format, subDays, differenceInDays } from 'date-fns';
import { getAuth } from "firebase/auth";
import { connect } from 'react-redux';
import moment from 'moment/moment';
import DatePicker from "react-datepicker";
import { confirm } from "react-confirm-box";
// import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts'
import AWS from 'aws-sdk';
import { enUS } from 'date-fns/locale';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Statistics = (props) => {
    const { data } = props;
    const history = useHistory();
    const options = {
        render: (message, onConfirm, onCancel) => {
            return (
                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} className={classes.confirmBox}>
                    <Grid item xs={1} sm={1} md={12}>
                        <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Typography variant="body2" sx={{ fontSize: '25px', fontFamily: 'Tajawal'}}>{message}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={onCancel}> تراجع </Button>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Button variant="contained" className={classes.submitBtn} size="small" onClick={onConfirm}> تأكيد </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            );
        }
    };
    const isFreePackage = props.data?.settings?.package?.type === 'free';
    // Check if the current route is '/statistics'
    // const isStatisticsPage = rest.path === '/statistics';

    //aws config for cloudwatch access
    // AWS.config.update({
    //     accessKeyId: 'AKIAWX5D3OVVP74GNV5F',
    //     secretAccessKey: 'Pm8EV0ogkDI7q846BF/kqfOSNhedVhgQj6r4eaf9',
    //     region: 'us-east-1' // replace with your region
    // });
    AWS.config.update({
        accessKeyId: 'AKIAWX5D3OVVG7UAGXOV',
        secretAccessKey: 'h/3sUSV96q+Yv9ar5lc0rqsBB7jAtU8EQdTYG03a',
        region: 'us-east-1' // replace with your region
    });
    const ses = new AWS.CloudWatch();
    const client = new AWS.SES({ region: "us-east-1" });
    let from = new Date();
    from.setDate(from.getDate() - 7);

    const [subscribers, setSubscribers] = useState(0);
    const [totalSubscribers, setTotalSubscribers] = useState(0);
    const [fromDate, setFromDate] = useState(from);
    const [toDate, setToDate] = useState(new Date());
    const [newSubscribers, setNewSubscribers] = useState(0);
    const [newUnSubscribers, setNewUnSubscribers] = useState(0);
    const [subscribersPercentage, setSubscribersPercentage] = useState(0);
    const [unSubscribersPercentage, setUnSubscribersPercentage] = useState(0);
    const [todayUnsubscribed, setTodayUnsubscribed] = useState(0);
    const [todayClicked, setTodayClicked] = useState(0);
    const [todayDelivered, setTodayDelivered] = useState(0);
    const [todayOpened, setTodayOpened] = useState(0);
    const [todayEmailTotal, setTodayEmailTotal] = useState(0);
    const [daysForPercentage, setDaysForPercentage] = useState(7);
    const { id, company } = props;

    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    const [_package, setPackage] = useState(
        {
            type: 'تجريبية',
            active: 'نشط',
            sentEmails: 0,
            totalEmails: 0
        }
    );

    const [series, setSeries] = useState([
        {
            name: 'النشرات المرسلة',
            data: []
        },
        {
            name: 'النشرات المستلمة',
            data: []
        },
        {
            name: 'النشرات التي تم فتحها',
            data: []
        },
        {
            name: 'النقرات',
            data: []
        },
    ])
    // const [graphCategories, setGraphCategories] = useState([]);
    const [graphOptions, setGraphOptions] = useState({
        series: series,
        options: {
            chart: {
                height: 150,
                type: 'area',
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                fontWeight: 400,
                itemMargin: {
                    horizontal: 15,
                    vertical: 30
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: []
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },

        },
    });
    useEffect(() => {
        const legendMarkers = document.querySelectorAll('.apexcharts-legend-marker');
        legendMarkers.forEach((marker) => {
            marker.style.width = '19px';
            marker.style.marginLeft = '10px';
        });
    }, [graphOptions]); // Ensure this runs after the chart renders

    const graphSelectDates = [
        {
            id: 'week',
            name: 'آخر أسبوع'

        },
        {
            id: 'month',
            name: 'آخر شهر'
        },
        {
            id: 'semester',
            name: '3 أشهر'

        },
        {
            id: 'semiyear',
            name: '6 أشهر'

        },
        {
            id: 'year',
            name: 'سنة'

        },
        {
            id: 'custom',
            name: 'تاريخ مخصص'
        }
    ];
    const [statOptionSelect, setStatOptionSelect] = useState(graphSelectDates[0].name);

    const CurrentDate = new Date();
    const defaultSelected = {
        from: subDays(CurrentDate, 4),
        to: CurrentDate
    };

    const [range, setRange] = useState(defaultSelected);
    let footer = <p>Please pick the first day.</p>;
    if (range?.from) {
        if (!range.to) {
            footer = <p>{format(range.from, 'PPP')}</p>;
        } else if (range.to) {
            footer = (
                <p>
                    {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
                </p>
            );
        }
    }


    const useStyles = makeStyles()(
        () => ({
            graphFilter: {
                '& .muirtl-j24kgu-MuiInputBase-root-MuiInput-root:before': {
                    borderBottom: 0
                }
            },
            confirmBox: {
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px'
            },
            submitBtn: {
                background: '#FF6150 !important',
                color: '#FFF !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            cancelBtn: {
                background: '#FFF !important',
                color: '#FF6150 !important',
                border: '1px solid #FF6150 !important',
                fontFamily: 'Tajawal, sans-serif !important',
                borderRadius: '10px'
            },
            btns: {
                color: '#FF6150'
            }

        })
    );

    const { classes } = useStyles();
    //DateRange box to statistics
    const [openDateRange, setOpenDateRange] = useState(false);
    const handleClickDateRange = () => {
        setOpenDateRange(true);
    };
    const handleCloseDateRange = () => {
        setOpenDateRange(false);
    };
    //DateRange box to statistics
    const convert_to_iso_Date = (date, type) => {
        date.setHours(0, 0, 0, 0);
        if (type == 'to') {
            date.setHours(23, 59, 59, 0);
        }
        return date.toISOString();
    }

    const subtractDaysFromDate = (date, numOfDays) => {
        const dateCopy = new Date(date.getTime());
        dateCopy.setDate(dateCopy.getDate() - numOfDays);
        // console.log(dateCopy);
        return dateCopy;
    }

    const todayBtn = () => {
        fetchData(fromDate, fromDate);
        setTotalSubscribers(subscribers);
    }
    const yesterdayBtn = () => {
        let from = subtractDaysFromDate(fromDate, 1);
        let to = fromDate;
        setTotalSubscribers(subscribers * 2);
        fetchData(from, to);
    }
    const lastWeekBtn = () => {
        let from = subtractDaysFromDate(fromDate, 7);
        let to = fromDate;
        setTotalSubscribers(subscribers * 7);
        fetchData(from, to);
    }
    const lastMonthBtn = () => {
        let from = subtractDaysFromDate(fromDate, 30);
        let to = fromDate;
        setTotalSubscribers(subscribers * 30);
        fetchData(from, to);
    }
    const customDateBtn = () => {

        let subtDates = (toDate.getDate() - fromDate.getDate()) + 1;
        console.log(subtDates);
        setTotalSubscribers(subscribers * subtDates);
        fetchData(fromDate, toDate);
    }
    const getNumOfSubscribers = async () => {
        const subRef = collection(db, "Subscribers");
        const q = query(subRef, where("company", "==", company));
        let data = await getDocs(q);
        setSubscribers(data.size);
    }
    function getDates(startDate, stopDate) {
        var dateArray = [];
        var currentDate = moment.utc(startDate); // use utc() to set start date to GMT
        var stopDate = moment.utc(stopDate); console.log(stopDate); // use utc() to set stop date to GMT
        while (currentDate <= stopDate) {
            dateArray.push(currentDate.format('YYYY-MM-DD')); // format date in GMT
            currentDate = currentDate.add(1, 'days');
        }
        const isoStrings = dateArray.map(date => moment.utc(date).toISOString()); // convert dates to ISO strings in GMT
        return isoStrings;
    }
    function processEmailData(diff, metricData) {
        const emailCounts = [];
        let added;

        for (let i = 0; i < diff.length; i++) {
            let date = new Date(diff[i]);
            let offset = date.getTimezoneOffset();
            let adjustedOffset = new Date(metricData[0]?.Timestamp).getTimezoneOffset();
            let adjustedDate = date.getTime() - (offset - adjustedOffset) * 60 * 1000;
            added = false;

            for (let y = 0; y < metricData.length; y++) {
                let dataDate = new Date(metricData[y].Timestamp).getTime() - new Date(metricData[y].Timestamp).getTimezoneOffset() * 60000;

                if (dataDate === adjustedDate) {
                    emailCounts.push(metricData[y].Sum);
                    added = true;
                    break;
                }
            }

            if (!added) {
                emailCounts.push(0);
            }
        }

        return emailCounts;
    }
    const fetchData = async (from, to) => {
        let diff = getDates(from, to);
        from.setHours(0, 0, 0, 0)
        to.setHours(0, 0, 0, 0)
        let from_timestamp = from.getTime();
        let to_timestamp = to.getTime();

        try {
            const response = await fetch('https://us-central1-mujaz-platform.cloudfunctions.net/getCloudWatchData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    from: from_timestamp,
                    to: to_timestamp,
                    company: company
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const result = await response.json();
            console.log(result.sent);
            const emailsSent = processEmailData(diff, result.sent || []);
            const emailsDelivered = processEmailData(diff, result.delivered || []);
            const emailsOpened = processEmailData(diff, result.opened || []);
            const emailsClicked = processEmailData(diff, result.clicked || []);
            setGraphOptions({
                ...graphOptions,
                series: [
                    {
                        name: 'النشرات المرسلة',
                        data: emailsSent
                    },
                    {
                        name: 'النشرات المستلمة',
                        data: emailsDelivered
                    },
                    {
                        name: 'النشرات التي تم فتحها',
                        data: emailsOpened
                    },
                    {
                        name: 'النقرات',
                        data: emailsClicked
                    },
                ],
                options: {
                    ...graphOptions.options,
                    xaxis: {
                        ...graphOptions.options.xaxis,
                        categories: diff
                    }
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    }
    const getFilteredStatistics = (value) => {
        console.log(value);
        if (value.id == '') {
            return;
        }
        let toDate = new Date();
        //let today = new Date();
        //toDate.setDate(today.getDate() + 1);
        let fromDate;
        setStatOptionSelect(value.name);
        switch (value.id) {
            case 'week':
                fromDate = subtractDaysFromDate(toDate, 7);
                setDaysForPercentage(7);
                fetchData(fromDate, toDate);
                break;
            case 'month':
                fromDate = subtractDaysFromDate(toDate, 30);
                setDaysForPercentage(30);
                fetchData(fromDate, toDate);
                break;
            case 'semester':
                fromDate = subtractDaysFromDate(toDate, 90); // 3 months
                setDaysForPercentage(90);
                fetchData(fromDate, toDate);
                break;
            case 'semiyear':
                fromDate = subtractDaysFromDate(toDate, 182); //6 months
                setDaysForPercentage(182);
                fetchData(fromDate, toDate);
                break;
            case 'year':
                fromDate = subtractDaysFromDate(toDate, 365); //1 year
                setDaysForPercentage(365);
                fetchData(fromDate, toDate);
                break;
            case 'custom':
                setOpenDateRange(true);
                break;
        }

    }
    const todayRep = async () => {
        let from = convert_to_iso_Date(new Date(), 'from');
        let to = convert_to_iso_Date(new Date(), 'to');
        let diff = getDates(from, to);
        // from.setHours(0, 0, 0, 0)
        // to.setHours(0, 0, 0, 0)
        let from_timestamp = new Date(from).getTime();
        let to_timestamp = new Date(to).getTime();

        try {
            const response = await fetch('https://us-central1-mujaz-platform.cloudfunctions.net/getCloudWatchData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    from: from_timestamp,
                    to: to_timestamp,
                    company: company
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const result = await response.json();
            const emailsSent = result.sent.length > 0 ? result?.sent[0]?.Sum : 0;
            const emailsDelivered = result.delivered.length > 0 ? result?.delivered[0]?.Sum : 0;
            const emailsOpened = result.opened.length > 0 ? result?.opened[0]?.Sum : 0;
            const emailsClicked = result.clicked.length > 0 ? result?.clicked[0]?.Sum : 0;

            setTodayEmailTotal(emailsSent);
            setTodayDelivered(emailsDelivered);
            setTodayOpened(emailsOpened);
            setTodayClicked(emailsClicked);
        } catch (err) {
            console.log(err.message);
        }

    }

    const newSubscribersFn = async () => {
        // Get the start and end timestamps for the specific date
        const today = new Date();
        const startTimestamp = Timestamp.fromDate(startOfDay(today));
        const endTimestamp = Timestamp.fromDate(endOfDay(today));

        let newUsers = query(collection(db, 'Subscribers'), where("company", "==", company), where('createdAt', '>=', startTimestamp), where('createdAt', '<=', endTimestamp));
        let allUsers = query(collection(db, 'Subscribers'), where("company", "==", company));
        let queryNewUsers = await getDocs(newUsers);
        let queryAllUsers = await getDocs(allUsers);
        console.log(queryNewUsers.docs.length);
        setNewSubscribers(queryNewUsers.docs.length);
        let percentage = 0;
        if (queryNewUsers.docs.length == 0 || queryAllUsers.docs.length == 0) {
            percentage = 0;
        } else {
            percentage = (queryNewUsers.docs.length / queryAllUsers.docs.length) * 100;
        }
        setSubscribersPercentage(percentage.toFixed(2));
    }
    const newUnSubscribersFn = async () => {
        const today = new Date();
        const startTimestamp = Timestamp.fromDate(startOfDay(today));
        const endTimestamp = Timestamp.fromDate(endOfDay(today));

        let newUnsubscribers = query(collection(db, 'Unsubscribers'), where("company", "==", company), where('createdAt', '>=', startTimestamp), where('createdAt', '<=', endTimestamp));
        let allUsers = query(collection(db, 'Subscribers'), where("company", "==", company));
        let queryNewUsers = await getDocs(newUnsubscribers);
        let queryAllUsers = await getDocs(allUsers);
        setNewUnSubscribers(queryNewUsers.docs.length);
        let percentage = 0;
        if (queryNewUsers.docs.length == 0 || queryAllUsers.docs.length == 0) {
            percentage = 0;
        } else {
            percentage = (queryNewUsers.docs.length / queryAllUsers.docs.length) * 100;
        }
        setUnSubscribersPercentage(percentage.toFixed(2));
    }
    const handleDateRange = () => {
        var timeDiff = range.to.getTime() - range.from.getTime();

        // Convert the time difference to days
        var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        console.log(daysDiff);
        console.log(differenceInDays(range.to, range.from));
        setDaysForPercentage(differenceInDays(range.to, range.from));
        fetchData(range.from, range.to);
    }
    useEffect(() => { console.log(range) }, [range])
    useEffect(() => {
        getNumOfSubscribers();
        fetchData(fromDate, toDate);
        todayRep();
        newSubscribersFn();
        newUnSubscribersFn();
        setPackage({
            type: data?.settings?.package?.type == 'free' ? 'تجريبية' : 'غير تجريبية',
            active: data?.settings?.package?.active ? 'نشط' : 'غير نشط',
            sentEmails: data?.settings?.package?.sentEmails,
            totalEmails: data?.settings?.package?.totalEmails
        })
    }, []);
    // useEffect(() => {
    //     if (isFreePackage) {
    //         const result = confirm("لا يمكنك الدخول إلى قسم الإحصائيات، هل تريد تطوير الباقة؟", options);
    //         if (result) {
    //             history.goBack();
    //             return null;
    //         }

    //     }
    // }, [])

    const CircularProgressWithLabel = (props) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate" {...props}
                    value={100}
                    size={90}
                    thickness={4}
                    sx={{ color: '#d3d3d3' }} />
                <CircularProgress
                    variant="determinate"
                    {...props}
                    size={90}
                    thickness={6}
                    sx={{
                        position: 'absolute', left: 0, color: '#FFCB00',
                        '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round', // Make the end of the stroke rounded
                        },
                    }}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Typography variant="caption" component="div" color="textSecondary" align='center' sx={{ fontSize: '10px', }}>
                            <strong style={{ fontSize: '15px' }}>{formatNumberInThousands(_package.sentEmails)}</strong> / <strong style={{ fontSize: '15px' }}>{formatNumberInThousands(_package.totalEmails)}</strong>
                        </Typography>
                        <Typography variant="body2" align='center' sx={{ fontSize: '10px', }} >
                            نشرة
                        </Typography>
                    </div>
                </Box>
            </Box>
        );
    };

    const formatNumberInThousands = (number) => {
        if (number >= 1000) {
            return Math.floor(number / 1000) + 'K';
        }
        return number;
    };

    return (
        <>

            <Dialog open={openDateRange} onClose={handleCloseDateRange}>
                <DialogTitle>إختر تاريخ مخصص</DialogTitle>
                <Paper elevation={0} style={{ padding: '30px', borderRadius: '10px' }}>
                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                        <Grid item xs={1} sm={1} md={12}>
                            <DayPicker
                                locale={enUS}
                                dir="rtl"
                                id="test"
                                mode="range"
                                defaultMonth={CurrentDate}
                                selected={range}
                                // footer={footer}
                                onSelect={setRange}
                                style={{
                                    '--rdp-accent-color': '#FF6150'
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={12}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outlined" className={classes.cancelBtn} size="small" onClick={handleCloseDateRange}> تراجع </Button>
                                </Grid>
                                <Grid item xs={1} sm={1} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button variant="contained" className={classes.submitBtn} size="small" onClick={() => { handleDateRange(); handleCloseDateRange(); }}> تأكيد </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
            {/* <Grid container spacing={4} mt={1} columns={{ xs: 1, sm: 1, md: 12 }}>
                <Grid item xs={1} sm={1} md={5}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={5.5}>
                                <Typography variant="subtitle1" align='center' sx={{ fontWeight: 'bold' }}>المرسلة اليوم</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المرسلة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayEmailTotal}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>المستلمة</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayDelivered}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>تم الفتح</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{todayOpened}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">النقرات</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{todayClicked}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">إلغاء الإشتراك</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align="center">البريد المزعج</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={5}>
                    <Paper elevation={0} style={{ padding: '20px' }}>
                        <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                            <Grid item xs={1} sm={1} md={5.5}>
                                <Typography pl={3} variant="subtitle1" align='center' sx={{ fontWeight: 'bold' }}>المشتركين&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                    <Grid item xs={1} sm={1} md={7.5}>
                                        <Grid container columns={{ xs: 1 }}>
                                            <Grid item xs={1}>
                                                <Typography variant="body2" align='center'>عدد المشتركين: {subscribers} مشترك</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }} mt={4}>
                                    <Grid item xs={1} sm={1} md={6}>
                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={8}>
                                                <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                            <Grid item xs={1} sm={1} md={8}>
                                                                <Typography variant="body2" color='red' style={{
                                                                    fontSize: '0.75rem',
                                                                    textAlign: 'left',
                                                                    lineHeight: 1.4,
                                                                    paddingTop: '6px'
                                                                }}>{unSubscribersPercentage}%-</Typography>
                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={4}>
                                                                <Typography variant="h6" align='center' style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '1.5rem' }}>{newUnSubscribers}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Typography style={{ fontSize: '0.80rem', textAlign: 'left', paddingLeft: '15px' }} variant="body2" align='center'>إلغاء الإشتراك</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ArrowDownwardIcon color="primary" fontSize="large" style={{
                                                    background: '#fff9ef',
                                                    borderRadius: '50px',
                                                    padding: '5px',
                                                    fontSize: '53px'
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={1} sm={1} md={6}>
                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                            <Grid item xs={8}>
                                                <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
                                                            <Grid item xs={1} sm={1} md={8}>
                                                                <Typography variant="body2" color='green' style={{
                                                                    fontSize: '0.75rem',
                                                                    textAlign: 'left',
                                                                    lineHeight: 1.4,
                                                                    paddingTop: '6px'
                                                                }}>{subscribersPercentage}%+</Typography>
                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={4}>
                                                                <Typography variant="h6" align='center' style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '1.5rem' }}>{newSubscribers}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={12}>
                                                        <Typography style={{ fontSize: '0.80rem', textAlign: 'left', paddingLeft: '15px' }} variant="body2" align='center'>مشترك جديد</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ArrowUpwardIcon color="primary" fontSize="large" style={{
                                                    background: '#fff9ef',
                                                    borderRadius: '50px',
                                                    padding: '5px',
                                                    fontSize: '53px'
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid> */}

            <Grid container mt={1} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{ justifyContent: "center", alignItems: "center", }}>
                <Grid item container direction="row" spacing={4} xs={12} sm={8} md={10} lg={10} sx={{ alignItems: "center", }}>

                    {/* المرسلة اليوم */}
                    <Grid item xs={1} sm={4} md={4} lg={4.5}>
                        <Paper elevation={0} style={{ padding: '10px 20px 10px 20px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={10.5}>
                                    <Typography variant="subtitle1" sx={{ fontSize: '12px', }}>المرسلة اليوم</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" alignItems="center" mt={2}>

                                <Grid item xs={4} sm={4} md={"auto"}>
                                    <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>المرسلة</Typography>
                                    <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayEmailTotal}</Typography>
                                    <Typography variant="body2" align="center" sx={{ fontSize: '12px', }}>النقرات</Typography>
                                    <Typography variant="body1" align="center" sx={{ fontWeight: 'bold', }}>{todayClicked}</Typography>
                                </Grid>

                                <Grid item xs={4} sm={4} md={"auto"} >
                                    <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>المستلمة</Typography>
                                    <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{todayDelivered}</Typography>
                                    <Typography variant="body2" align="center" sx={{ fontSize: '12px', }}>إلغاء الإشتراك</Typography>
                                    <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>

                                </Grid>

                                <Grid item xs={4} sm={4} md={"auto"}>
                                    <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>تم الفتح</Typography>
                                    <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{todayOpened}</Typography>
                                    <Typography variant="body2" align="center" sx={{ fontSize: '12px', }}>البريد المزعج</Typography>
                                    <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid >

                    {/* المشتركين */}
                    <Grid item xs={1} sm={4} md={4} lg={4.5} >
                        <Paper elevation={0} style={{ padding: '10px 20px 10px 20px' }}>
                            <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                <Grid item xs={1} sm={1} md={10.5}>
                                    <Typography variant="subtitle1" sx={{ fontSize: '12px', }}>المشتركين</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '15px' }}>
                                    <Grid container spacing={4} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={12}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{subscribers}  مشترك</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} justifyContent="space-between" alignItems="center" style={{ paddingTop: '20px' }}>
                                        <Grid item xs={6} sm={6} md={"auto"} style={{ paddinRight: '20px' }}>
                                            <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', marginLeft: '-10px' }} align='center'>{newUnSubscribers} إلغاء الإشتراك </Typography>
                                            <Typography variant="body2" color='#FF6150' style={{
                                                fontSize: '0.75rem',
                                                textAlign: 'left',
                                                lineHeight: 1.4,
                                            }}>
                                                <ArrowDownwardIcon fontSize="20px" />  {unSubscribersPercentage}%
                                                <span style={{ fontSize: '10px', fontWeight: 'normal', color: 'grey', marginRight: '4px' }}>اخر اسبوع</span>

                                            </Typography>

                                        </Grid>

                                        <Grid item xs={6} sm={6} md={"auto"}>
                                            <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', marginLeft: '-10px', }} align='center'>{newSubscribers} مشترك جديد </Typography>
                                            <Typography variant="body2" color='#54D2D2' style={{
                                                fontSize: '0.75rem',
                                                textAlign: 'left',
                                                lineHeight: 1.4,
                                            }}>

                                                <ArrowUpwardIcon fontSize="20px" />{subscribersPercentage}%
                                                <span style={{ fontSize: '10px', fontWeight: 'normal', color: 'grey', marginRight: '4px' }}>اخر اسبوع</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Paper>
                    </Grid >

                    {/* الباقة */}
                    <Grid item xs={1} sm={3} md={3} lg={3} >
                        <Paper elevation={0} style={{ padding: '10px 20px 10px 20px' }}>
                            <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 10 }}>
                                <Grid item xs={1} sm={1} md={8}>
                                    <Typography variant="subtitle1" sx={{ fontSize: '12px', }}>الباقة</Typography>
                                </Grid>
                                {/* <Grid item xs={1} sm={1} md={1.5}>
                                <Link
                                    style={{ color: 'black' }}
                                    component="button"
                                    onClick={() => {
                                        history.push('/');
                                    }}
                                >
                                    <MoreVertIcon style={{ fontSize: '20px', }} />
                                </Link>

                            </Grid> */}
                                <Grid item xs={1} sm={1} md={12} style={{ paddingTop: '9px' }}>
                                    <Grid container spacing={8} columns={{ xs: 1, sm: 1, md: 12 }}>
                                        <Grid item xs={1} sm={1} md={4}>
                                            <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 12 }}>

                                                <Grid item xs={1} sm={1} md={12}>
                                                    <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>المستهلك</Typography>
                                                    <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>{_package.sentEmails}</Typography>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={12}>
                                                    <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>الباقي </Typography>
                                                    <Typography variant="body2" align='center' sx={{ fontSize: '12px', }}>{_package.totalEmails}</Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid item xs={1} sm={1} md={8} style={{ textAlign: "center" }}>
                                            <CircularProgressWithLabel value={(_package.sentEmails / _package.totalEmails) * 100} />
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid >
                </Grid>
            </Grid >

            {/* Statistics graph */}
            <Grid container spacing={2} mt={3} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{ justifyContent: "center", alignItems: "center", }}>
                <Grid item sm={8} md={10} lg={10}>
                    <Paper elevation={0} sx={{ padding: '10px 30px ' }}>
                        <Grid container spacing={4} mt={1} columns={{ xs: 1, sm: 1, md: 12, lg: 12 }}>

                            <Grid item lg={3}>
                                <Autocomplete
                                    className={classes.graphFilter}
                                    disableClearable
                                    size='small'
                                    options={graphSelectDates}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={graphSelectDates[0]}
                                    onChange={(event, value) => getFilteredStatistics(value)}
                                    id="disable-clearable"
                                    renderInput={(params) => (
                                        <TextField {...params} label="" variant="outlined" />
                                    )}
                                    renderOption={(props, option) => (
                                        <Box style={{ fontSize: 14 }} {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <Chart
                                    options={graphOptions.options}
                                    series={graphOptions.series}
                                    type="area"
                                    height={350}
                                />
                            </Grid>
                        </Grid>
                        {/* <Autocomplete
                                className={classes.graphFilter}
                                disableClearable
                                size='small'
                                options={graphSelectDates}
                                getOptionLabel={(option) => option.name}
                                defaultValue={graphSelectDates[0]}
                                onChange={(event, value) => getFilteredStatistics(value)}
                                id="disable-clearable"
                                renderInput={(params) => (
                                    <TextField {...params} label="" variant="standard" />
                                )}
                                renderOption={(props, option) => (
                                    <Box style={{ fontSize: 14 }} {...props}>
                                        {option.name}
                                    </Box>
                                )}
                            />
                        <Chart
                            options={graphOptions.options}
                            series={graphOptions.series}
                            type="area"
                            height={350}
                        /> */}
                    </Paper>
                </Grid>
            </Grid>

            {/* المرسلة أخر أسبوع */}
            <Grid container spacing={2} mt={1} columns={{ mt: 3, xs: 12, md: 12, lg: 12, }} sx={{ justifyContent: "center", alignItems: "center", }}>
                <Grid item sm={8} md={10} lg={10}>
                    <Paper elevation={0} sx={{ padding: '10px 30px ' }}>
                        <Grid item >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>المرسلة {statOptionSelect}</Typography>
                        </Grid>
                        <Grid item container justifyContent="space-between" alignItems="center" mt={3}>
                            <Grid item xs={1} sm={1} md={"auto"}>
                                <Typography variant="body2" sx={{ fontSize: 'medium' }} align='center'>المرسلة</Typography>
                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                <Typography variant="body2" align='center'>{((graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0) / (subscribers * daysForPercentage)) * 100).toFixed(2)}%</Typography>
                                {/* <Grid container columns={{ xs: 1 }}> */}
                                {/* <Grid item xs={1}>
                                           
                                        </Grid> */}
                                {/* <Grid item xs={1}>
                                            <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" align='center'>{((graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0) / (subscribers * daysForPercentage)) * 100).toFixed(2)}%</Typography>
                                        </Grid> */}
                                {/* </Grid> */}
                            </Grid>
                            <Grid item xs={1} sm={1} md={"auto"}>
                                {/* <Grid container columns={{ xs: 1 }}> */}
                                {/* <Grid item xs={1}> */}
                                <Typography variant="body2" sx={{ fontSize: 'small' }} align='center'>المستلمة</Typography>
                                <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                <Typography variant="body2" align='center'>{((graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                {/* </Grid> */}
                                {/* <Grid item xs={1}>
                                        <Typography variant="body1" align='center' sx={{ fontWeight: 'bold' }}>{graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="body2" align='center'>{((graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[0].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                    </Grid> */}
                                {/* </Grid> */}
                            </Grid>
                            <Grid item xs={1} sm={1} md={"auto"}>
                                <Typography variant="body2" sx={{ fontSize: 'small' }} align='center'>تم الفتح</Typography>
                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                <Typography variant="body2" align="center">{((graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                {/* <Grid container columns={{ xs: 1 }}>
                                    <Grid item xs={1}>
                                        <Typography variant="body2" align='center'>تم الفتح</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="body2" align="center">{((graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[1].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                            <Grid item xs={1} sm={1} md={"auto"}>
                                <Typography variant="body2" sx={{ fontSize: 'small' }} align="center">النقرات</Typography>
                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{graphOptions.series[3].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                <Typography variant="body2" align="center">{((graphOptions.series[3].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                {/* <Grid container columns={{ xs: 1 }}>
                                    <Grid item xs={1}>
                                        <Typography variant="body2" align="center">النقرات</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>{graphOptions.series[3].data.reduce((acc, curr) => acc + curr, 0)}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="body2" align="center">{((graphOptions.series[3].data.reduce((acc, curr) => acc + curr, 0) / graphOptions.series[2].data.reduce((acc, curr) => acc + curr, 0)) * 100).toFixed(2)}%</Typography>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                            <Grid item xs={1} sm={1} md={"auto"}>
                                <Typography variant="body2" sx={{ fontSize: 'small' }} align="center">إلغاء الإشتراك</Typography>
                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                {/* <Grid container columns={{ xs: 1 }}>
                                    <Grid item xs={1}>
                                        <Typography variant="body2" align="center">إلغاء الإشتراك</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                            <Grid item xs={1} sm={1} md={"auto"}>
                                <Typography variant="body2" sx={{ fontSize: 'small' }} align="center">البريد المزعج</Typography>
                                <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                {/* <Grid container columns={{ xs: 1 }}>
                                    <Grid item xs={1}>
                                        <Typography variant="body2" align="center">البريد المزعج</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="body1" align="center" sx={{ fontWeight: 'bold' }}>0</Typography>
                                    </Grid>
                                </Grid> */}
                            </Grid>

                        </Grid>

                    </Paper>

                </Grid>

            </Grid>

            {/* النشرات */}
            <Grid container spacing={2} mt={3} columns={{ mt: 3, xs: 12, md: 12, lg: 12, }} sx={{ justifyContent: "center", alignItems: "center", }}>
                <Grid item mb={3} sm={8} md={10} lg={10}>
                    <Paper elevation={0} sx={{ padding: '15px 30px' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}> النشرات</Typography>

                        <TableContainer component={Paper} style={{ boxShadow: 'none', marginTop: '30px' }}>
                            <Table sx={{ minWidth: 500, }} aria-label="simple table">
                                <TableHead sx={{
                                    background: '#F5F6FA',
                                    borderRadius: '10px',
                                    overflow: 'hidden',

                                }}>
                                    <TableRow sx={{
                                        background: '#F5F6FA',
                                        '&:first-of-type': {
                                            borderTopLeftRadius: '10px',
                                            borderTopRightRadius: '10px',
                                            borderBottomRightRadius: '10px',
                                            borderBottomLeftRadius: '10px',
                                        },
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}>
                                        <TableCell sx={{ borderBottom: "none", flex: 1, }} align="center">
                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                النشرة
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "none", flex: 1, }} align="center">
                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                التاريخ
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={{ borderBottom: 'none', flex: 1, }} align="center">
                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                معدل الارسال
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none', flex: 1, }} align="center">
                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                معدل الاستلام
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "none", borderBottomLeftRadius: '10px', flex: 1, }} align="center">
                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                معدل الفتح
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {/* <TableBody>
                                    {items.map((row, index) => {
                                        if (index < currentPage * currentLimit && (currentPage === 1 ? true : index >= currentPage * currentLimit - 10)) {
                                            return (
                                                <>
                                                    <TableRow
                                                        key={index}
                                                        index={index}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 },
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                {row.name}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                {row.email}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                {row.responsibility}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" sx={{ borderBottom: "none", flex: 1, }} align="center">
                                                            <Typography variant="body2" sx={{ fontSize: 'small' }}>
                                                                {row.responsibility}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ padding: '0px', borderBottom: 'none', flex: 1, }} align="center">
                                                            <IconButton aria-label="edit" className={classes.btns} onClick={() => handleSubVisible(index, row)}>
                                                                <Edit style={{ fontSize: '1rem' }} />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" className={classes.btns} onClick={() => handleDeleteSubscriber(row)}>
                                                                <Delete style={{ fontSize: '1rem' }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    {SubVisible[index] && <AddEditSubscriber
                                                        name={row.name}
                                                        email={row.email}
                                                        id={row.id}
                                                        index={index}
                                                        handleAddSubscriber={handleAddSubscriber}
                                                        buttonName="حفظ" />}
                                                </>
                                            )
                                        }
                                    }
                                    )}
                                </TableBody> */}
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>


            </Grid>
        </>

    );

}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Statistics);
