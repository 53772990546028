import React, { useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import backgroundImage from '../Public/assets/img/mujaz/mujaz-bg.jpg';
import HeaderNavBar from '../../components/HeaderNavBar';
import FooterNavBar from '../../components/FooterNavBar';
import { useSigninCheck } from 'reactfire';
import { getAuth, signOut } from 'firebase/auth';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const TermsAndPolicies = () => {
	const history = useHistory();
	const signInCheckResult = useSigninCheck();
	const [signedIn, setSignedIn] = useState(signInCheckResult.data.signedIn);
	const auth = getAuth();
	const gotologin = () => {
		history.push('/login');
	}
	const gotosignup = () => {
		history.push('/signup');
	}
	return (
		<>
			<nav className="navbar navbar-expand-lg classic center-nav transparent navbar-light">
				<div className="container flex-lg-row flex-nowrap align-items-center">
					<div className="navbar-brand">
						<a href="/">
							<img src="./img/icon/logo-01.png" width="200" />
						</a>
					</div>
					<div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
						<div className="offcanvas-body mx-xl-auto d-flex flex-column h-100">
							<ul className="navbar-nav">
								<li className="nav-item">
									<a className="nav-link" href='/#home'>الرئيسية</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href='/#aboutus'>من نحن</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href='/#specifics'>مميزات</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href='/#prices'>الأسعار</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href='/#contactus'>التواصل معنا</a>
								</li>
							</ul>
							<div className="d-lg-none mt-auto pt-6 pb-6 order-4">
								<a href="mailto:first.last@email.com" className="link-inverse">info@email.com</a>
								<br /> 00 (123) 456 78 90 <br />
								<nav className="nav social social-white mt-4">
									<a href="https://x.com/mujaz_io"><i class="uil uil-twitter"></i></a>
									<a href="https://www.facebook.com/mujazplatform?mibextid=ZbWKwL"><i className="uil uil-facebook-f"></i></a>
									<a href="https://www.instagram.com/mujaz.io/"><i className="uil uil-instagram"></i></a>
									<a href="https://www.linkedin.com/company/mujaz/"><i className="uil uil-linkedin"></i></a>
									{/* <a href="#"><i className="uil uil-dribbble"></i></a> */}
									{/* <a href="#"><i className="uil uil-youtube"></i></a> */}
								</nav>
							</div>
						</div>
					</div>
					<div className="navbar-other ms-lg-4">
						<ul className="navbar-nav flex-row align-items-center ms-auto">
							<li className="nav-item"><a className="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-info"><i className="uil uil-info-circle"></i></a></li>
							<li className="nav-item me-lg-3 d-none d-md-block">
								{!signedIn ?
									<>
										<Button size='small' variant='contained' onClick={gotologin}>تسجيل الدخول</Button>&nbsp;&nbsp;
										<Button size='small' variant='outlined' onClick={gotosignup}>تسجيل حساب جديد</Button>
									</> :
									<Button size='small' variant='contained' onClick={() => signOut(auth)} >تسجيل الخروج</Button>}
							</li>
							<li className="nav-item  d-lg-none">
								<button className="hamburger offcanvas-nav-btn"><span></span></button>
							</li>
						</ul>
					</div>
				</div>
			</nav>
			<div className="offcanvas offcanvas-end text-inverse" id="offcanvas-info" data-bs-scroll="true">
				<div className="offcanvas-header">
					<h3 className="text-white fs-30 mb-0">Mujaz</h3>
					<button type="button" style={{ border: 'none', color: 'black', background: 'transparent' }} className="btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"><CloseRoundedIcon color="black" /></button>
				</div>
				<div className="offcanvas-body">
					<div className="widget mb-8">
						<p>موجز، منصّة إرسال بريد إلكتروني جماعي، تُمكّنك من إرسال وإدارة الرسائل البريدية والإخبارية والإعلانية باللغة العربية.</p>
					</div>
					<div className="widget mb-8">
						<h4 className="widget-title text-white mb-3">معلومات الاتصال</h4>
						<address> تركيا <br /> إسطنبول  </address>
						<a href="mailto:hello@mujaz.io">hello@mujaz.io</a><br /> +9 0 535 593 8150
					</div>
					<div className="widget mb-8">
						<h4 className="widget-title text-white mb-3">اعرف المزيد</h4>
						<ul className="list-unstyled">
							<li><a href="/terms-policies#terms-conditions" className="link-body" style={{ fontSize: '0.9rem' }}> شروط الاستخدام </a></li>
							<li><a href="/terms-policies#privacy-policy" className="link-body" style={{ fontSize: '0.9rem' }}>سياسة الخصوصية</a></li>
							<li><a href="/terms-policies#return-policy" className="link-body" style={{ fontSize: '0.9rem' }}>سياسة الاسترجاع</a></li>
						</ul>
					</div>
					<div className="widget">
						<h4 className="widget-title text-white mb-3">تابعونا</h4>
						<nav className="nav social social-white">
							<a href="https://x.com/mujaz_io"><i class="uil uil-twitter"></i></a>
							<a href="https://www.facebook.com/mujazplatform?mibextid=ZbWKwL"><i className="uil uil-facebook-f"></i></a>
							<a href="https://www.instagram.com/mujaz.io/"><i className="uil uil-instagram"></i></a>
							<a href="https://www.linkedin.com/company/mujaz/"><i className="uil uil-linkedin"></i></a>
							<a href="#"><i className="uil uil-dribbble"></i></a>
							<a href="#"><i className="uil uil-youtube"></i></a>
						</nav>
					</div>
				</div>
			</div>

			<section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
				data-image-src="../Public/assets/img/mujaz/mujaz-bg.jpg"
				style={{ backgroundImage: `url(${backgroundImage})` }}
			>
				<div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
					<div className="row">
						<div className="col-lg-8 mx-auto">
							<h1 className="display-1 mb-3">الشروط والسياسات</h1>
							<nav className="d-inline-block" aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="#">الرئيسية</a></li>
									<li className="breadcrumb-item active" aria-current="page">الشروط والسياسات</li>
								</ol>
							</nav>

						</div>

					</div>

				</div>
			</section>

			<div className="container">
				<div className="row gx-0">
					<aside className="col-xl-3 sidebar sticky-sidebar mt-md-0 py-16 d-none d-xl-block">
						<div className="widget">
							<nav id="sidebar-nav">
								<ul className="list-unstyled text-reset">
									<li><AnchorLink className="nav-link scroll active" href='#terms-conditions'>1. شروط الاستخدام</AnchorLink></li>
									<li><AnchorLink className="nav-link scroll" href='#privacy-policy'>2. سياسة الخصوصية</AnchorLink></li>
									<li><AnchorLink className="nav-link scroll" href='#return-policy'>3. سياسة الاسترجاع</AnchorLink></li>
								</ul>
							</nav>

						</div>

					</aside>

					<div className="col-xl-8">
						<section id="terms-conditions" className="wrapper pt-16">
							<div className="card">
								<div className="card-body p-10">
									<h2 className="mb-3">1. شروط الاستخدام</h2>
									<p>عند استخدامك لمنصة موجز أنت توافق على الشروط والأحكام التالية، إضافة لسياسة الخصوصية، هذه الشروط تحكم طريقة تعامل الإدارة مع المحتوى وتمثل الاتفاق بين العميل وموجز على طريقة استخدام المنصة.</p>
									<h4 className='mb-2'>حماية المستخدمين</h4>

									<ul className="bullet-bg bullet-soft-primary mb-2">
										<li>في حالات إيقاف الحساب بسبب اختراق أو شك من إدارة منصّة موجز، يحق لمنصّة موجز طلب وثائق شخصية وأرقام هواتف وأي معلومة تثبت شخصية المستخدم وبعدها يحق للإدارة إعادة الحساب أو الاستمرار في إيقافه حسب المعلومات المتوفرة، علما أنه يتم إخفاء معلومات الحساب والصورة الرمزية حفاظاً على خصوصية المستخدم الموقوف.</li>
										<li>بشكل افتراضي، يمتلك مستخدم المنصّة كامل الحقوق الملكية الفكرية وحقوق التأليف والنشر للمحتوى الذي ينشره في موجز.</li>
										<li>يلتزم المستخدم بدفع الاجور بعد الاشتراك في موجز واستخدام اي وسائل دفع مزورة او مسروقة تؤدي الى الحظر المباشر للنشرة وصاحب الحساب.</li>
									</ul>
									<h4 className='mb-2'>أسباب إيقاف الحساب في منصّة موجز</h4>
									<ul className="bullet-bg bullet-soft-primary mb-2">
										<li>نحتفظ في منصّة موجز بحقّنا في تجميد أي حساب أو إيقافه بشكل دائم، إيقاف الحسابات يكون بسبب استخدام غير قانوني أو غير مناسب لخدمات الموقع.</li>
										<li>قد يتلقى المستخدمون تنبيها بسبب انتهاك الشروط والقوانين أو أي استخدام غير مناسب للموقع. التنبيه يتوصل به المستخدم على الموقع أو عبر البريد الإلكتروني المرتبط، التنبيه لا يوقف نشاط الحساب لكنه قد يؤدي لإيقاف الحساب بشكل دائم حسب شدة المخالفة.</li>
										<li>مخالفة شروط الاستخدام في منصّة موجز قد تؤدي لإيقاف الحساب بشكل دائم.</li>
									</ul>
									<h4 className='mb-2'>تجديد الاشتراك</h4>
									<p>يتم تجديد الاشتراك بشكل تلقائي عبر طريقة الدفع التي اخترتها في حال لم تقم بتجميد الاشتراك أو إلغائه.
										في حال لم يتمكن النظام من تجديد الاشتراك لمشكلة في وسيلة الدفع يدخل الحساب في فترة سماح لمدة 10 أيام، يمكنك تحديث طريقة الدفع وتجديد الاشتراك خلال هذه المدة دون عواقب، بعد هذه المدة يحق لنا إيقاف الوصول لمميزات الباقة المدفوعة.
									</p>
									<h4 className='mb-2'>إلغاء الاشتراك</h4>
									<p>لك الحق باستخدام موجز بعد إلغاء الاشتراك حتى تاريخ دورة الفوترة (Billing Cycle) القادمة التي تظهر في حسابك، بعد انقضاء هذه المدة سوف يتم نقل نشرتك إلى الباقة المجانية حتى الاشتراك مرة ثانية.</p>
									<h4 className='mb-2'>استرداد المبلغ</h4>
									<p>لا يمكنك طلب استعادة المبلغ بعد إلغاء الاشتراك، كون أن موجز توفر لك باقة مجانية قبل الاشتراك المدفوع، يمكنك من خلالها تجريب جميع الخصائص المتاحة في النظام، و ١٤ يوم تجربة بعد الاشتراك في أحد الباقات المدفوعة.</p>
									<h4 className='mb-2'>المحتوى الخاص بنشرتك</h4>
									<p>موجز توفر البرامج كخدمة “SAAS” ونحن بدورنا غير مسؤولين عن المحتوى الذي تقوم بنشره على نشرتك وانت تنشره على مسؤوليتك الشخصية.</p>
									<p className='mb-0'>لا يمكنك في موجز تقديم المحتوى الجنسي او المحرض على الكراهيَة أو العنف او التطرف، في موجز نقدم لك الأداة التي تساعدك على نشر العلم وخلاف ذلك يعتبر مخالف لشروط استخدام المنصة.</p>
								</div>

							</div>

						</section>
						<section id="privacy-policy" className="wrapper pt-16">
							<div className="card">
								<div className="card-body p-10">
									<h2 className="mb-3">2. سياسة الخصوصية</h2>
									<p>في موجز، خصوصية المستخدمين وأمان معلوماتهم الّتي يشاركونها تهمنا كثيرًا، وكموقع قائم على توفير البرامج كخدمة “SAAS” نتعهد بإبقاء سياسة الخصوصية الخاصة بنا بسيطة، سهلة وقابلة للقراءة والفهم بشكل سريع. في حال تسجيلك في موجز فأنت توافق تلقائيًا على سياسة الخصوصية هذه، إضافة لشروط الاستخدام المذكورة في صفحة شروط الاستخدام.</p>
									<p>تلتزم إدارة الموقع، في حدود المسموح لها وفق القانون المنظم، بعدم كشف أي معلومات شخصية عن المشترك مثل الاسم أو عنوان البريد الإلكتروني وغيرها. علاوة على ذلك، لن يتم تبادل، أو تداول أي من تلك المعلومات أو بيعها لأي طرف آخر طالما كان ذلك في حدود قدرات إدارة الموقع الممكنة، ولن يُسمح بالوصول إلى المعلومات إلا للأشخاص المؤهلين والمحترفين الذين يشرفون على المنصّة.
										انتفاء المسؤولية القانونية
									</p>
									<p>يقر المُستخدِم بأنه المسؤول الوحيد عن طبيعة الاستخدام الذي يحدده لمنصّة موجز، وتخلي إدارة منصّة موجز طرفها، إلى أقصى مدى يجيزه القانون، من كامل المسؤولية عن أية خسائر، أو أضرار أو نفقات أو مصاريف يكبدها المُستخدِم أو يتعرض لها هو أو أي طرف آخر من جراء استخدام منصّة موجز، أو العجز عن استخدامها.</p>
									<h4 className='mb-2'>حالات انقطاع الخدمة والسهو والخطأ في منصّة موجز</h4>
									<p>تبذل إدارة المنصّة قصارى جهدها للحرص والحفاظ على استمرار عمل الموقع الإلكتروني بدون مشاكل، رغم ذلك قد تقع في أي وقت أخطاء وحالات سهو وانقطاع للخدمة وتأخير لها، وفي مثل هذه الحالات سنتوقع من المستخدمين الصبر حتى تعود الخدمة إلى معدلها الطبيعي.</p>
									<h4 className='mb-2'>حساب المشترك وكلمة السر وأمن المعلومات</h4>
									<p>يختار المشترك كلمة سر / مرور لحسابه، وسيُدخل عنوانا بريديا خاصا به لمراسلته عليه، وتكون مسؤولية حماية كلمة السر هذه وعدم مشاركتها أو نشرها على المشترك، وفي حال حدوث أي معاملات باستخدام كلمة السر هذه فسيتحمل المشترك كافة المسؤوليات المترتبة على ذلك، دون أدني مسؤولية على منصّة موجز.</p>
									<p>يتحمل المشترك كامل المسؤولية عن جميع المحتويات الخاصة به، التي يرفعها وينشرها عبر الموقع.</p>
									<p>لا يتم الاحتفاظ بأي من المعلومات التي يتم حذفها من قبل المستخدمين، عندما تحذف محتوى من موجز او نشرة فسيمحى بشكل نهائي لا يمكن استرجاعه.</p>
									<p>يلتزم المشترك بكل شروط الاستخدام، وبألا ينشر أي شيء يخالف الشريعة الإسلامية بأي شكل من الأشكال، أو في أغراض غير قانونية، على سبيل المثال لا الحصر: القرصنة ونشر وتوزيع مواد أو برامج منسوخة، أو الخداع والتزوير أو الاحتيال أو التهديد أو إزعاج أي شخص أو شركة أو جماعة أو نشر مواد إباحية أو جنسية أو نشر فيروسات أو ملفات تجسس أو وضع روابط إلى مواقع تحتوي على مثل هذه المخالفات.</p>
									<p>يمنع انتهاك حقوق الملكية الفكرية، أو تشويه سمعة شخص أو مؤسسة أو شركة أو تعمد نشر أي معلومات تسبب ضررا لشركة أو شخص أو دولة أو جماعة وعدم وضع مواد قرصنة وبرامج مسروقة وجميع ما يخالف الأعراف والقوانين المنظمة، ويكون المشترك مسؤولا مسؤولية كاملة عن كل ما يقدمه عبر حسابه على الموقع.</p>
									<h4 className='mb-2'>المعلومات التي يتم جمعها</h4>
									<p>عند إنشائك نشرة جديدة على منصّة موجز سوف نطلب منك المعلومات اللازمة حول نشرتك مثل الاسم والنطاق والبريد الالكتروني لغرض التواصل وإرسال التنبهات حول المنتج والخدمة المقدمة.</p>
									<p>أسماء المستخدمين وعناوين البريد الإلكتروني والبيانات العامة تخزّن على مخدّماتنا.</p>
									<p>كلمات المرور تخزن بشكل مشفر غير قابل للقراءة.</p>
									<p>سجلات التصفح، إحصائيات الصفحات، والبيانات الأخرى المتعلقة بالزيارات والزوّار سواء عناوين IP، بلد المصدر، لغة المتصفح، وغيرها يتم تخزينها والوصول لها، نعدك أن هذه المعلومات لن يتم توظيفها بأي شكل مؤذ ولن يتم بيعها لأي طرف ثالث بأي شكل من الأشكال.</p>
									<h4 className='mb-2'>ملفات تعريف الارتباط، الكوكيز</h4>
									<p>عندما تسجّل الدخول نقوم أيضاً بتهيئة ملفات لتعريف الارتباط من أجل حفظ معلومات دخولك وخيارات شاشة العرض الخاصة بك. عند قيامك بتسجيل الخروج من الحساب، يتم حذف ملفات تعريف ارتباط تسجيل الدخول.</p>
									<p>نستخدم ملفات تعريف الارتباط لتحسين تجربة المستخدم، جميع هذه الملفات تحذف عند القيام بعملية تسجيل الخروج، يمكنك تعطيل حفظ ملفات الارتباط في إعدادات المتصفح ولكن ربما تتوقف بعض ميزات الموقع عن العمل في هذه الحالة.</p>
									<h4 className='mb-2'>بيانات الدفع</h4>
									<p>يمكنك دفع تكاليف الاشتراك الخاصة بنشرتك عن طريق منصة عبر وسائل الدفع المتنوعة، موجز لا تقوم بحفظ هذه البيانات او تخزينها باي شكل من الاشكال.</p>
									<h4 className='mb-2'>الحقوق العائدة لك على بياناتك</h4>
									<p>يمكنك طلب الحصول على ملف يتم تصديره من البيانات الشخصية التي نحتفظ بها عنك، بما في ذلك أي بيانات قدمتها لنا. يمكنك أيضًا طلب حذف أي بيانات شخصية نحتفظ بها عنك. هذا لا يشمل أي بيانات نحن ملزمون بالحفاظ عليها لأغراض إدارية أو قانونية أو أمنية.</p>
									<h4 className='mb-2'>التعديل على سياسة الخصوصية</h4>
									<p className='mb-0'>يحق لنا التعديل على سياسة الخصوصية والتطوير عليها من الوقت الى الاخر نرجو من المستخدمين والزوار مراجعتها بشكل دوري.
										لمزيد من الاستفسارت يرجى عدم التردد في التواصل معنا علىhello@mujaz.io
									</p>
								</div>

							</div>

						</section>
						<section id="return-policy" className="wrapper pt-16">
							<div className="card">
								<div className="card-body p-10">
									<h2 className="mb-3">3. سياسة الاسترجاع</h2>
									<p>يمكن للمشترك في  منصة موجز للنشرات البريدية استرداد أي مبلغ مالي مدفوع على الأداة خلال 14 يوم من تاريخ الدفع.
										يجب على العميل إبلاغ موظفي شركة موجز للنشرات البريدية بالأعطال بالوسائل المناسبة، ومن ذلك عن طريق البريد الإلكتروني التالي hello@mujaz.io
									</p>
									<p className='mb-0'>تلتزم شركة موجز تكنولوجي بتقديم الصيانة الدورية الاعتيادية للأداة خلال العام، والأصل أن تقوم شركة موجز للنشرات البريدية بمعالجة الأعطال البرمجية خلال مدة لا تتجاوز 72 ساعة من وقت علمنا الحقيقي ببلاغ العميل.</p>
								</div>

							</div>

						</section>
					</div>

				</div>
			</div>
			<div className="progress-wrap">
				<svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
					<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
				</svg>
			</div>
			<FooterNavBar />
		</>
	);
};

export default TermsAndPolicies;
