import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Avatar,
    Button,
    TextField,
    Link,
    Paper,
    Box,
    Grid,
    Typography,
    Stack,
    Container,
    Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { getAuth, fetchSignInMethodsForEmail, signInWithEmailAndPassword, signOut, setPersistence, onAuthStateChanged } from 'firebase/auth';
import { doc, addDoc, deleteDoc, getDoc, getFirestore, getDocs, documentId, setDoc, collection, query, where } from 'firebase/firestore';
import { connect } from 'react-redux';
import firebase, { initializeApp } from 'firebase/app';
import speakeasy from "@levminer/speakeasy";
import backgroundImage from '../Public/assets/img/mujaz/mujaz-bg.jpg';
const errorMessages = {
    'auth/wrong-password': 'رمز مرور خاطئ',
    'auth/internal-error': 'خطأ داخلي',
    'auth/invalid-user-token': 'رمز المستخدم غير صالح',
    'auth/invalid-verification-code': 'رمز التحقق غير صالح',
    'auth/invalid-email': 'بريد إلكتروني خاطئ',
    'auth/timeout': 'نفذ الوقت',
    'auth/user-not-found': 'بريد إلكتروني غير مسجل'
};

const Verify2FA = (props) => {
    const location = useLocation();
    const { email, password, secret } = location.state;
    // console.log(email)
    // console.log(password)
    // console.log(secret);
    const [token, setToken] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let company = '';
    let compId = '';
    let compData = '';
    let loggedInUser = {};
    const auth = getAuth();
    const db = getFirestore();


    const getCompanyRef = async (username, uid) => {
        const userRef = collection(db, "Users");
        const qUser = query(userRef, where('uid', "==", uid));
        let _data = await getDocs(qUser);
        _data.forEach((user) => {
            console.log(user);
            company = user.data().company;
            loggedInUser = user.data()
            // {
            // 	company: user.data().company,
            // 	email: user.data().email,
            // 	logo: user.data().logo,
            // 	name: user.data().name,
            // 	responsibility: user.data().responsibility,
            // 	uid: user.data().uid
            // }
        })


        const companyRef = collection(db, "Companies");
        // const q = query(companyRef, where("personalProfile.personalData.username", "==", username));
        const q = query(companyRef, where("personalProfile.companyData.company", "==", company));
        let data = await getDocs(q);
        data.forEach((comp) => {
            console.log(comp.data());
            compData = comp.data();
            compId = comp.id;
        })

        props.setCompany(company, compId, compData, loggedInUser);
    }
    const onLoginWithGivenCredentials = async () => {
        console.log(email)
        console.log(password)
        setErrorMessage('');
        setIsLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    // setPersistence(auth, 'SESSION');
                    getCompanyRef(user.email, user.uid);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setErrorMessage(errorMessages[error.code]);
                    setIsLoading(false);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const verifyOTP = () => {
        if (!token) {
            setErrorMessage('أدخل رمز المرور'); // no OTP entered yet
            return;
        } else {
            if (token.length < 6) {
                setErrorMessage('طول الرمز أقل من 6 أرقام');
                return;
            } else {
                let _isValid = speakeasy.totp.verify({
                    secret: secret,
                    encoding: 'base32',
                    token: token,
                });
                if (_isValid) {
                    console.log('valid');
                    onLoginWithGivenCredentials();
                } else {
                    setErrorMessage('رمز مرور خاطئ');
                }
            }
        }
    }
    return (
        <>
            <section className="wrapper image-wrapper bg-overlay bg-overlay-light-300 text-white"
            style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/mujaz-platform.appspot.com/o/system-images%2Fshutterstock_21515359633%5B1%5D.jpg?alt=media&token=ebf0bf23-ed73-4059-aa10-5e909e98a9a4)`, backgroundPosition: '0px -130px' }}
             >
                <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h1 className="display-1 mb-3">تحقق من رمز جوجل</h1>
                            <nav className="d-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">تحقق من رمز جوجل</li>
                                </ol>
                            </nav>

                        </div>

                    </div>

                </div>
            </section>

            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
                            <div className="card">
                                <div className="card-body p-11 text-center">

                                    <Grid container spacing={2} columns={{ xs: 1, sm: 1, md: 1 }}>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography variant='body2' className="lead mb-6 text-end">الرجاء إدخال رمز جوجل الخاص بك</Typography>
                                            {Boolean(errorMessage) && (
                                                <Alert variant="standard" color="error" style={{ width: '100%' }}>
                                                    <Typography>{errorMessage}</Typography>
                                                </Alert>
                                            )}
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="token"
                                                label="رمز المرور"
                                                autoComplete="token"
                                                value={token}
                                                onChange={({ target: { value } }) => setToken(value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <LoadingButton
                                                className="btn btn-primary btn-login w-100 mb-2"
                                                variant="contained"
                                                onClick={() => verifyOTP()}
                                                loading={isLoading}
                                                disabled={isLoading}
                                            >تأكيد الرمز</LoadingButton>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container>
										<Grid item xs>
											<Link href="/forget_password" variant="body2" style={{ textDecoration: 'none' }} className="hover">
												نسيت كلمة المرور؟
											</Link>
										</Grid>
										<Grid item xs>
											<Link href="/signup" variant="body2" style={{ textDecoration: 'none' }} className="hover">
												تسجيل حساب جديد
											</Link>
										</Grid>
									</Grid> */}
                                    {/* <p className="mb-0">ليس لديك حساب؟ <a href="./signup.html" className="hover">تسجيل حساب جديد</a></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const dispatchCompany = (dispatch) => {
    return {
        setCompany: (company, id, data, loggedIn) => { dispatch({ type: 'SET_COMPANY', value: company, id: id, data: data, loggedIn: loggedIn }) }
    }
}

export default connect(null, dispatchCompany)(Verify2FA);
