import {
    Autocomplete,
    Button,
    Card,
    Snackbar,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect, useRef } from 'react';
import { generateKey } from '../../utils';
import SideBarMenu from '../../components/SideBarMenu';
import { makeStyles } from "tss-react/mui";
import { doc, addDoc, getDoc, collection, getFirestore, getDocs, setDoc } from 'firebase/firestore';
import HTMLViewer from '../../components/HTMLViewer';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Preview from '../../components/Preview';
import { connect } from 'react-redux';
import trash from '../../img/icons/trash.svg';
import RichTextEditor from '../../atoms/RichTextEditor';

const CurrencyRow = ({ onChange, onDelete, id, item }) => {
    const [currency, setCurrency] = useState(
        item
            ? item
            : {
                key: id,
                name: '',
                icon: '',
                salesValue: 0,
                purshaseValue: 0,
            }
    );

    useEffect(() => {
        onChange(currency);
    }, [currency]);

    const useStyles = makeStyles()(
        () => ({
            fieldStyle: {
                '& .MuiInputBase-root': {
                    fontSize: '0.7rem', // Smaller font size
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.7rem', // Smaller label font size
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                },
                "& fieldset": {
                    border: 'none',
                },
            },

        }))

    const { classes } = useStyles();

    return (
        <Stack spacing={1} direction="row" alignItems="center">
            <Autocomplete
                options={[
                    '🇪🇺',
                    '🇬🇧',
                    '🇺🇸',
                    '🇹🇷',
                    '🇱🇧',
                    '🇸🇾',
                    '🇮🇶',
                    '🇵🇸',
                    '🇪🇬',
                    '🇸🇩',
                    '🇩🇿',
                    '🇹🇳',
                    '🇲🇦',
                    '🇱🇾',
                    '🇯🇴',
                    '🇮🇷',
                    '🇷🇺',
                    '🇸🇦',
                    '🇰🇼',
                    '🇾🇪',
                    '🇴🇲',
                    '🇧🇭',
                    '🇦🇪',
                    '🇶🇦',
                ]}
                disableClearable
                onChange={(e, icon) => setCurrency({ ...currency, icon })}
                renderInput={(params) => <TextField {...params} label="العلم" />}
                className={classes.fieldStyle}
            />
            <TextField
                fullWidth
                //label="البلد"
                placeholder="البلد"
                variant="outlined"
                value={currency.name}
                onChange={({ target: { value } }) => setCurrency({ ...currency, name: value })}
                className={classes.fieldStyle}
            />
            <TextField
                //label="القيمة"
                placeholder="قيمة الشراء"
                fullWidth
                variant="outlined"
                value={currency.purshaseValue == 0 ? '' : currency.purshaseValue}
                onChange={({ target: { value } }) => setCurrency({ ...currency, purshaseValue: value })}
                className={classes.fieldStyle}
            />
            <TextField
                //label="القيمة"
                placeholder="قيمة المبيع"
                fullWidth
                variant="outlined"
                value={currency.salesValue == 0 ? '' : currency.salesValue}
                onChange={({ target: { value } }) => setCurrency({ ...currency, salesValue: value })}
                className={classes.fieldStyle}
            />
            <IconButton color='primary' onClick={onDelete}>
                <img src={trash} alt="Trash Logo" style={{ width: '24px', height: '24px', }} />
            </IconButton>
        </Stack>
    );
};

const NewsCurrency = (props) => {
    const [currencies, setCurrencies] = useState(
        [
            {
                key: generateKey(),
                name: '',
                icon: '',
                // value: '',
                salesValue: 0,
                purshaseValue: 0,
            },
        ]
    );
    const [body, setBody] = useState('')
    const [intro, setIntro] = useState('');
    const [items, setItems] = useState([]);
    const [snackBar, setSnackBar] = useState({
        isOpen: false,
        message: '',
        background: '#FFC300'
    });
    const previewCompRef = useRef();
    const db = getFirestore();
    const { company, data } = props;
    const useStyles = makeStyles()(
        () => ({
            main: {
                width: '100%',
                '@media only screen and (min-width: 1200px)': {
                    width: '100%',
                },
            },
            container: {
                boxSizing: 'border-box',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
                flexDirection: 'row',
                background: '#fefefe',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            welomeText: {
                color: '#ff6633',
                fontWeight: 700,
            },
            paper: {
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 210,
            },
            content: {
                background: '#EFF0F7',
                borderRadius: '25px',
                padding: '25px 15px',
            }

        })
    );

    const { classes } = useStyles();

    const getDateId = (_company) => {
        return `${_company}_${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
            }-${new Date().getFullYear()}`;
    };
    const saveNewsCurrency = async () => {
        const docTitle = getDateId(company);
        let data = await getDoc(doc(db, 'NewsLibrary', docTitle));
        if (data.data()) {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: data.data().News,
                Stocks: data.data().Stocks,
                Weather: data.data().Weather,
                Image: data.data().Image,
                Currency: {
                    body: body,
                    id: generateKey(),
                    items: currencies,
                    category: 'أسعار المال',
                    title: 'المال',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                data: data.data().data
            }).then((data) => {
                console.log('done update');
            });
        } else {
            await setDoc(doc(db, 'NewsLibrary', docTitle), {
                News: [],
                Stocks: '',
                Weather: '',
                Image: [],
                Currency: {
                    body: body,
                    id: generateKey(),
                    items: currencies,
                    category: 'أسعار المال',
                    title: 'المال',
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`
                },
                data: {
                    date: `${parseInt(new Date().getDate())}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
                        }-${new Date().getFullYear()}`,
                    company: company
                }
            }).then((data) => {
                console.log('done');
            });
        }
        setSnackBar({
            isOpen: true,
			background: '#51F9C3',
            message: 'تم الحفظ في مكتبة الفئات',
        });
    }

    const PreviewFn = () => {
        setItems([{
            body: body,
            items: currencies,
            category: 'أسعار المال',
            title: 'المال',
            date: `${parseInt(new Date().getDate())}-${new Date().getMonth() + 1
                }-${new Date().getFullYear()}`
        }])

        previewCompRef.current.showPreview();
    }
    useEffect(() => {
        if (items.length > 0) {
            console.log(items);
            previewCompRef.current.showPreview();
        }
    }, [items]);

    return (
        <>
            <Grid container sx={{ justifyContent: 'center', }} lg={12}>

                <Grid container mt={1} sx={{ justifyContent: 'space-between', }} lg={10}>
                    <Grid item xs={6} lg={5.8} >
                        <Stack spacing={3} className={classes.content} direction="column">
                            <Stack direction="column" divider={<Divider />} spacing={2}>
                                {currencies.map((city, index) => (
                                    <CurrencyRow
                                        key={city.key}
                                        id={city.key}
                                        onChange={(newCity) => {
                                            let temp = [...currencies];
                                            temp.splice(index, 1, newCity);
                                            setCurrencies(temp);
                                        }}
                                        item={city}
                                        onDelete={() => {
                                            let temp = [...currencies];
                                            temp.splice(index, 1);
                                            setCurrencies(temp);
                                        }}
                                    />
                                ))}
                            </Stack>
                            <Button
                                fullWidth
                                onClick={() =>
                                    setCurrencies([
                                        ...currencies,
                                        {
                                            key: generateKey(),
                                            name: '',
                                            icon: '',
                                            value: '',
                                        },
                                    ])
                                }
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: '#000000', // Set the icon color to black
                                    },
                                }}
                            >
                                <AddCircleOutlineIcon />
                            </Button>
                            <RichTextEditor defaultValue={body} onChange={setBody} minHeightRichText={400}/>
                            {/* <Grid container mt={4} columns={{ sm: 1, md: 12 }} justifyContent="space-between" alignItems="center">
                                <Grid item xs={6} md={4} >
                                    <Button size='small' variant="outlined" style={{ color: '#FF6150', borderRadius: '15px', padding: '10px' }} endIcon={<SearchIcon />} onClick={PreviewFn}>معاينة</Button>
                                </Grid>
                                <Grid item xs={6} md={6} sx={{ display: 'grid' }}>
                                    <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '15px', padding: '10px', }} endIcon={<SaveIcon />} onClick={saveNewsCurrency}>حفظ في مكتبة الفئات</Button>
                                </Grid>
                            </Grid> */}
                        </Stack>

                        <Grid container mt={4} columns={{ sm: 12, md: 12, lg: 12 }} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                            <Grid item xs={6} md={6} lg={6} >
                                <Button size='small' variant="contained" style={{ backgroundColor: '#FF6150', borderRadius: '5px', padding: '10px', }} onClick={saveNewsCurrency}>حفظ في مكتبة المحتوى</Button>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3} sx={{ textAlign: "right" }}>
                                <Button size='small' variant="outlined" className="text-start" style={{ color: '#FF6150', borderRadius: '5px', padding: '10px', width: '60%' }} onClick={PreviewFn}>معاينة</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} lg={5.8} style={{ paddingTop: 0 }}>
                        <Preview data={items} compData={data} intro={intro} ref={previewCompRef} />
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                ContentProps={{
                    sx: {
                        background: snackBar.background,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }
                }}
                open={snackBar.isOpen}
                autoHideDuration={6000}
                onClose={() => setSnackBar({ ...snackBar, isOpen: false })}
                message={snackBar.message}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    console.log(state);
    return state
}

export default connect(mapStateToProps)(NewsCurrency);